import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import classes from 'assets/style/documentsPicker.module.scss';
import { ReactComponent as UploadMedia } from 'assets/images/uploadMedia.svg';
import DocumentPreview from 'components/DocumentPreview';
import { toast } from 'react-toastify';
import Button from 'components/Button';

const DocumentPicker = ({
  acceptFileTypes,
  document,
  setDocument,
  errorMsg,
  clearErrors,
  addButtonText,
  formatNotSupportedMsg,
}) => {
  const handleSetDocument = useCallback(
    (e) => {
      const {
        target: { files: [doc = null] = [] },
      } = e;
      if (acceptFileTypes.includes(doc.type)) {
        setDocument(doc);
      } else if (formatNotSupportedMsg) {
        toast.error(formatNotSupportedMsg);
      }
      e.target.value = '';
    },
    [setDocument, acceptFileTypes, formatNotSupportedMsg],
  );

  const handleClearDocument = useCallback(() => {
    setDocument(null);
    clearErrors();
  }, [setDocument, clearErrors]);

  return (
    <div className={classes.container}>
      {document ? (
        <DocumentPreview
          file={document}
          onDelete={handleClearDocument}
        />
      ) : (
        <div className={classes.addButtonContainer}>
          <Button variant="light" className={classes.addButton}>
            <Form.Group controlId="picture">
              <Form.Label>
                <UploadMedia />
                {addButtonText}
              </Form.Label>
              <Form.File>
                <Form.File.Input
                  onChange={handleSetDocument}
                  accept={acceptFileTypes.toString()}
                  isInvalid={!!errorMsg}
                  className={classes.input}
                />
              </Form.File>
            </Form.Group>
          </Button>
        </div>
      )}
      {!!errorMsg && <div className="d-block invalid-feedback">{errorMsg}</div>}
    </div>
  );
};

DocumentPicker.propTypes = {
  acceptFileTypes: PropTypes.arrayOf(PropTypes.string),
  document: PropTypes.instanceOf(File),
  errorMsg: PropTypes.string,
  setDocument: PropTypes.func,
  clearErrors: PropTypes.func,
  addButtonText: PropTypes.string,
  formatNotSupportedMsg: PropTypes.string,
};

DocumentPicker.defaultProps = {
  acceptFileTypes: ['image/png, image/jpeg'],
  document: null,
  errorMsg: null,
  setDocument: () => {},
  clearErrors: () => {},
  addButtonText: null,
  formatNotSupportedMsg: null,
};

export default DocumentPicker;
