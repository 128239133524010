import React, {
  useState,
  useMemo,
} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import classes from 'assets/style/disputeRecap.module.scss';
import Button from 'components/Button';
import DisputeMessage from 'components/DisputeMessage';
import {
  RESPONSE_ACCEPT_REGEXP,
  RESPONSE_REJECT_REGEXP,
  TYPE_SOLUTION,
  TYPE_PARTIAL_REFUND,
  TYPE_ISSUE_TYPE,
  TYPE_ADD_EVIDENCE,
  TYPE_ADD_ANOTHER_EVIDENCE,
  TYPE_REVIEW_AND_SEND,
  TYPE_ACCEPT,
  TYPE_BUYER_RESPONSE,
} from 'helpers/constants';
import { cleanResponseSlug } from 'helpers/cleanSlugs';

const DisputeRecap = ({
  transaction = {},
  role,
  steps = [],
  submitting,
  submit = () => {},
  hideRecap = () => {},
}) => {
  const [agreeTos, setAgreeTos] = useState();
  const [tosError, setTosError] = useState();

  const {
    issueType: transactionIssueType,
    messages = [],
    subTotal,
  } = useMemo(() => (transaction), [transaction]);

  const issueType = useMemo(() => (
    cleanResponseSlug(steps.find(({ type }) => (type === TYPE_ISSUE_TYPE))?.response) || transactionIssueType
  ), [steps, transactionIssueType]);

  const response = useMemo(() => (
    cleanResponseSlug(steps.find(({ type, response }) => (
      (type === TYPE_SOLUTION && cleanResponseSlug(response).match(RESPONSE_REJECT_REGEXP))
      || (type === TYPE_ACCEPT && cleanResponseSlug(response).match(RESPONSE_ACCEPT_REGEXP))
      || (type === TYPE_BUYER_RESPONSE)
    ))?.response)
  ), [steps]);

  const {
    proposedSolution: previouslyProposedSolution,
    proposedRefund: previouslyProposedRefund,
  } = useMemo(() => (
    messages.length ? messages[messages.length - 1] : {}
  ), [messages]);

  const proposedSolution = useMemo(() => (
    response.match(RESPONSE_ACCEPT_REGEXP)
      ? previouslyProposedSolution
      : cleanResponseSlug(steps.find(({ type, response }) => (
        type === TYPE_SOLUTION && !cleanResponseSlug(response).match(RESPONSE_REJECT_REGEXP)
      ))?.response)
  ), [
    response,
    previouslyProposedSolution,
    steps,
  ]);

  const proposedRefund = useMemo(() => {
    if (response.match(RESPONSE_ACCEPT_REGEXP)) {
      return previouslyProposedRefund;
    }

    if (response.match(RESPONSE_REJECT_REGEXP)) {
      return 0;
    }

    return steps.find(({ type }) => (type === TYPE_PARTIAL_REFUND))?.response || subTotal;
  }, [
    response,
    previouslyProposedRefund,
    steps,
    subTotal,
  ]);

  const data = useMemo(() => (
    steps.filter(({ type }) => (
      type !== TYPE_ISSUE_TYPE
      && type !== TYPE_ACCEPT
      && type !== TYPE_BUYER_RESPONSE
      && type !== TYPE_ADD_EVIDENCE
      && type !== TYPE_ADD_ANOTHER_EVIDENCE
      && type !== TYPE_SOLUTION
      && type !== TYPE_PARTIAL_REFUND
      && type !== TYPE_REVIEW_AND_SEND
    ))
  ), [steps]);

  const medias = useMemo(() => (
    steps
      .filter(({ evidence }) => (!!evidence))
      .map(({ evidence: { document: media, comment } = {} }, id) => ({ id, media, comment }))
  ), [steps]);

  const returnAddress = useMemo(() => (
    steps.find(({ returnAddress }) => (!!returnAddress))?.returnAddress
  ), [steps]);

  const { t } = useTranslation(['dispute_recap', issueType]);

  const message = useMemo(() => ({
    proposedSolution,
    response,
    proposedRefund,
    medias,
    data,
  }), [
    proposedSolution,
    response,
    proposedRefund,
    medias,
    data,
  ]);

  return (
    <div className={classes.container}>
      <DisputeMessage
        isRecap
        className={classes.recap}
        transaction={{
          ...transaction,
          issueType,
        }}
        message={message}
        index={messages.length}
        role={role}
      />
      {returnAddress && (
        <div className={classes.returnAddress}>
          <p>
            <Trans
              t={t}
              i18nKey="return_address"
              values={{ returnAddress: `${returnAddress?.recipientName}, ${returnAddress?.formattedAddress}` }}
            />
          </p>
        </div>
      )}
      <div className={tosError ? classes.checkboxError : classes.checkbox}>
        <Form.Check
          type="checkbox"
          label={t('buttons.checkbox')}
          id="checkbox"
          onChange={({ target: { checked } = {} }) => {
            setAgreeTos(checked);
            setTosError(false);
          }}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          variant="light"
          onClick={() => {
            if (agreeTos) {
              submit({
                issueType,
                proposedSolution,
                response,
                proposedRefund,
                data,
                medias,
                returnAddress,
              });
            } else {
              setTosError(true);
            }
          }}
          isLoading={submitting}
        >
          {messages?.length ? t('buttons.send_response') : t('buttons.send_request')}
        </Button>
        <Button
          variant="light"
          onClick={hideRecap}
        >
          {t('buttons.edit')}
        </Button>
      </div>
    </div>
  );
};

export default DisputeRecap;
