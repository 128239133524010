import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/manualAddressForm.module.scss';
import { useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';
import CountriesSelect from 'components/CountriesSelect';
import validateGoogleAddress from 'helpers/validateGoogleAddress';

const { parseEmptyFields } = validateGoogleAddress();

const ManualAddressForm = ({
  isValidateGoogleAddress,
  setIsValidateGoogleAddress,
  allowedCountries,
}) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    setErrors,
    setFieldError,
  } = useFormikContext();

  const { t } = useTranslation('return_address_form');

  const manualAddressDefaults = useMemo(
    () => ({
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      country: '',
      countryIso2: '',
    }),
    [],
  );

  const firstRender = useRef(true);

  const resetForm = useCallback(() => {
    setFieldValue('manualAddress', manualAddressDefaults);
    setErrors({});
  }, [manualAddressDefaults, setErrors, setFieldValue]);

  useEffect(() => {
    if (isValidateGoogleAddress) {
      setFieldValue('isManualAddress', true);
      setFieldValue('manualAddress', {
        ...manualAddressDefaults,
        ...values.address,
      });
      parseEmptyFields(values.address).forEach((field) => {
        setFieldError(`manualAddress.${field}`, t('required_field'));
      });
    }
  }, [
    isValidateGoogleAddress,
    manualAddressDefaults,
    setFieldError,
    setFieldValue,
    t,
    values.address,
  ]);

  useEffect(() => {
    if (errors.address === t('invalid_address')) {
      setIsValidateGoogleAddress(true);
    }
  }, [errors.address, setIsValidateGoogleAddress, t]);

  useEffect(() => {
    if (
      (!firstRender.current
        || (firstRender.current && !values.address?.isManualAddress))
      && !isValidateGoogleAddress
    ) {
      resetForm();
      setErrors({});
    }
    firstRender.current = false;
    setIsValidateGoogleAddress(false);
  }, [
    values.isManualAddress,
    isValidateGoogleAddress,
    resetForm,
    setErrors,
    setIsValidateGoogleAddress,
    values.address?.isManualAddress,
  ]);

  const isManualFormVisible = values.isManualAddress;

  return (
    <div className={classes.manualAddressFormCheckout}>
      <div
        className={classNames(classes.manualAddressFormCheckout__form, {
          [classes['manualAddressFormCheckout__form--visible']]:
            isManualFormVisible,
        })}
      >
        <Form.Group
          controlId="addressLine1"
          className={classes.manualAddressFormCheckout__formGroup}
        >
          <Form.Label className={classes.label}>{t('address_line_1')}</Form.Label>
          <Form.Control
            name="manualAddress.addressLine1"
            value={values.manualAddress.addressLine1}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={
              touched?.manualAddress?.addressLine1
              && !!errors?.manualAddress?.addressLine1
            }
            placeholder={t('address_line_1')}
            className={classes.manualAddressFormCheckout__input}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.manualAddress?.addressLine1}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          controlId="addressLine2"
          className={classes.manualAddressFormCheckout__formGroup}
        >
          <Form.Label className={classes.label}>{t('address_line_2')}</Form.Label>
          <Form.Control
            name="manualAddress.addressLine2"
            value={values.manualAddress.addressLine2}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={
              touched?.manualAddress?.addressLine2
              && !!errors?.manualAddress?.addressLine2
            }
            placeholder={t('address_line_2')}
            className={classes.manualAddressFormCheckout__input}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.manualAddress?.addressLine2}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          controlId="postCode"
          className={classes.manualAddressFormCheckout__formGroup}
        >
          <Form.Label className={classes.label}>{t('postcode')}</Form.Label>
          <Form.Control
            name="manualAddress.postcode"
            value={values.manualAddress.postcode}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={
              touched?.manualAddress?.postcode
              && !!errors?.manualAddress?.postcode
            }
            placeholder={t('postcode')}
            className={classes.manualAddressFormCheckout__input}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.manualAddress?.postcode}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          controlId="city"
          className={classes.manualAddressFormCheckout__formGroup}
        >
          <Form.Label className={classes.label}>{t('city')}</Form.Label>
          <Form.Control
            name="manualAddress.city"
            value={values.manualAddress.city}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={
              touched?.manualAddress?.city && !!errors?.manualAddress?.city
            }
            placeholder={t('city')}
            className={classes.manualAddressFormCheckout__input}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.manualAddress?.city}
          </Form.Control.Feedback>
        </Form.Group>
        <CountriesSelect allowedCountries={allowedCountries} />
      </div>
    </div>
  );
};

export default ManualAddressForm;
