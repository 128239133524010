import React from 'react';
import cx from 'classnames';
import classes from 'assets/style/stickyButtons.module.scss';

const StickyButtons = ({
  className,
  children,
}) => (
  <div>
    <div className={cx(classes.placeholder, className)}>
      {children}
    </div>
    <div className={cx(classes.sticky, className)}>
      {children}
    </div>
  </div>
);

export default StickyButtons;
