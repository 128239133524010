import React, {
  useState,
  useCallback,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import classes from 'assets/style/buttonWithConfirmationModal.module.scss';

const ButtonWithConfirmationModal = ({
  modalTitle,
  modalMessage,
  confirmVariant,
  cancelVariant = 'light',
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm = Promise.resolve(),
  onCancel = () => {},
  errorMessage,
  children,
  ...props
}) => {
  const errorToast = useCallback(() => toast.error(errorMessage), [errorMessage]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isConfirming, setConfirming] = useState(false);

  return (
    <>
      <Modal
        show={modalVisible}
        centered
        contentClassName={classes.container}
      >
        <Modal.Header>
          <Modal.Title className={classes.title}>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.text}>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant={confirmVariant}
            className={classes.button}
            isLoading={isConfirming}
            onClick={() => {
              setConfirming(true);
              onConfirm()
                .catch(() => {
                  errorToast();
                })
                .finally(() => {
                  setModalVisible(false);
                  setConfirming(false);
                });
            }}
          >
            {confirmButtonLabel}
          </Button>
          <Button
            variant={cancelVariant}
            className={classes.button}
            onClick={() => {
              setModalVisible(false);
              onCancel();
            }}
          >
            {cancelButtonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        {...props}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {children}
      </Button>
    </>
  );
};

export default ButtonWithConfirmationModal;
