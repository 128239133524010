import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import CurrencyInput from 'react-currency-input-field';
import { ReactComponent as MinusSign } from 'assets/images/minus-sign.svg';
import { ReactComponent as PlusSign } from 'assets/images/plus-sign.svg';
import classes from 'assets/style/amountAnswer.module.scss';

const AmountAnswer = ({
  initialAmount = 0,
  max,
  min,
  currency = 'EUR',
  onSubmit = () => {},
}) => {
  const { t, i18n } = useTranslation('amount_answer');

  const [value, setValue] = useState(initialAmount / 100);
  const [floatValue, setFloatValue] = useState(initialAmount / 100);

  const intlConfig = useMemo(() => ({
    locale: i18n.language,
    currency,
  }), [i18n.language, currency]);

  const cleanValue = (newVal) => (
    newVal.toFixed(2).toString()
    // newVal.toFixed(2).toString().replace(/\.0*$/, '') // If we don't want to display decimals with integers
  );

  const decrement = useCallback((prev) => {
    if (Number.isNaN(parseFloat(prev))) {
      return prev;
    }

    if (parseFloat(prev) - 1 < min / 100) {
      return prev;
    }

    return parseFloat(prev) - 1;
  }, [min]);

  const increment = useCallback((prev) => {
    if (Number.isNaN(parseFloat(prev))) {
      return prev;
    }

    if (parseFloat(prev) + 1 > max / 100) {
      return prev;
    }

    return parseFloat(prev) + 1;
  }, [max]);

  const submit = useCallback(() => {
    onSubmit(floatValue * 100);
  }, [floatValue, onSubmit]);

  return (
    <div className={classes.container}>
      <InputGroup className={classes.amountField}>
        <InputGroup.Prepend className={classes.prepend}>
          <Button
            className={classes.minusBtn}
            variant="light"
            onClick={() => {
              setValue((prev) => (cleanValue(decrement(prev))));
              setFloatValue((prev) => (decrement(prev)));
            }}
          >
            <MinusSign />
          </Button>
        </InputGroup.Prepend>
        <FormControl
          as={CurrencyInput}
          className={classes.input}
          intlConfig={intlConfig}
          decimalScale={2}
          decimalLimit={2}
          value={value}
          onValueChange={(value, name, { float: floatValue = 0 } = {}) => {
            if (floatValue < min / 100 || floatValue > max / 100) {
              return;
            }

            setValue(value);
            setFloatValue(floatValue);
          }}
          disableAbbreviations
          allowNegativeValue={false}
        />
        <InputGroup.Append className={classes.append}>
          <Button
            className={classes.plusBtn}
            variant="light"
            onClick={() => {
              setValue((prev) => (cleanValue(increment(prev))));
              setFloatValue((prev) => (increment(prev)));
            }}
          >
            <PlusSign />
          </Button>
        </InputGroup.Append>
      </InputGroup>
      <p className={classes.help}>{t('help')}</p>
      <Button
        variant="dark"
        className={classes.submit}
        onClick={submit}
      >
        {t('submit')}
      </Button>
    </div>
  );
};

export default AmountAnswer;
