export const SYNC_LOGGED_IN = 'sync/LOGGED_IN';
export const SYNC_REFRESH_TOKEN = 'sync/REFRESH_TOKEN';
export const SYNC_LOGGED_OUT = 'sync/LOGGED_OUT';

export const syncLoggedIn = (accessToken, expiresAt, scope) => ({
  // For sync with other tabs
  type: SYNC_LOGGED_IN,
  accessToken,
  expiresAt,
  scope,
});

export const syncRefreshToken = (accessToken, expiresAt, scope) => ({
  // For sync with other tabs
  type: SYNC_REFRESH_TOKEN,
  accessToken,
  expiresAt,
  scope,
});

export const syncLoggedOut = () => ({
  // For sync with other tabs
  type: SYNC_LOGGED_OUT,
});
