/* eslint-disable import/no-unused-modules */
export const HANDLE_GREETING_MODAL = 'auth/HANDLE_GREETING_MODAL';
export const REFRESH_ME = 'me/REFRESH_ME';
export const UPDATE_ME = 'me/UPDATE_ME';
export const UPDATE_MY_PASSWORD = 'me/UPDATE_MY_PASSWORD';
export const UPDATE_MY_LANG = 'me/UPDATE_MY_LANG';
export const DELETE_MY_ACCOUNT = 'me/DELETE_MY_ACCOUNT';
export const ADD_AVATAR = 'me/ADD_AVATAR';
export const DELETE_AVATAR = 'me/DELETE_AVATAR';
export const GET_AVATAR = 'me/GET_AVATAR';
export const GET_AVATAR_ID = 'me/GET_AVATAR_ID';
/* eslint-enable import/no-unused-modules */

export const handleGreetingModal = (show) => ({
  type: HANDLE_GREETING_MODAL,
  show,
});

export const refreshMe = () => ({
  type: REFRESH_ME,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dispute/users/me/',
    },
  },
});

export const loggedIn = (state) => state.persistent.meReducer.loggedIn;
export const selectGreetingModal = (state) => state.persistent.meReducer.greetingModal;
export const avatarUrl = (state) => state.persistent.meReducer?.avatarUrl;

export const selectCurrentUserId = (state) => state.persistent.meReducer?.me?.id;
export const selectCurrentUserVerifyStatus = (state) => state.persistent.meReducer?.me?.identityVerifyStatus;
export const selectCurrentUserAddress = (state) => state.persistent.meReducer?.me?.address;
