// Intl import, don't move this !!
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/es';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/es';
import { IntlProvider } from 'react-intl';
// Intl import
import React, { useState, useEffect } from 'react';
import {
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { useCookies } from 'react-cookie';
import { BrowserTracing } from '@sentry/tracing';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import LoadingScreen from 'components/LoadingScreen';
import { useLoadingScreen } from 'helpers/LoadingScreenContext';
import { getToken } from 'logic/actions/authActions';
import { refreshMe } from 'logic/actions/meActions';
import { sentryDSN, sentryEnvironmentName, sentryReleaseVersion } from 'helpers/env';
import DisputeResolution from 'pages/DisputeResolution';
import {
  LANG_COOKIE_NAME,
  LANG_COOKIE_OPT,
} from 'helpers/constants';

if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new BrowserTracing()],
    environment: sentryEnvironmentName,
    release: sentryReleaseVersion,
    tracesSampleRate: 1.0,
    beforeSend: (event = {}) => {
      const { extra: { __serialized__: { error = {} } = {} } = {} } = event; /* eslint-disable-line no-underscore-dangle */

      if (
        error?.message
          && error.message.match(/request failed with status code/i)
      ) {
        return null;
      }

      if (error && typeof error.status === 'number' && [0, 400, 401, 403, 404, 500].includes(error.status)) {
        return null;
      }

      return event;
    },
  });
  Sentry.setUser({ email: 'Not logged in' });
}

const App = ({
  me: {
    displayName,
    lang,
  } = {},
  refreshMe,
  loggedIn,
  getToken,
}) => {
  const { t, i18n } = useTranslation('app');

  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const { token } = queryString.parse(search);

  const setLoadingScreen = useLoadingScreen();
  const [cookies, setCookie] = useCookies([LANG_COOKIE_NAME]);

  const [initInProgress, setInitInProgress] = useState(false);

  useEffect(() => {
    if (lang) {
      const cookieLang = cookies[LANG_COOKIE_NAME];
      const browserLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';

      if (lang !== browserLang) {
        i18n.changeLanguage(lang);
      }

      if (lang !== cookieLang) {
        setCookie(LANG_COOKIE_NAME, lang, LANG_COOKIE_OPT);
      }
    }
  }, [cookies, i18n, setCookie, lang]);

  // useEffect(() => {
  //   const currentLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';
  //   /* eslint-disable no-underscore-dangle */
  //   window._axcb = window._axcb || [];
  //   window._axcb.push((sdk) => {
  //     sdk.setCookiesVersion(currentLang);
  //   });
  //   /* eslint-enable no-underscore-dangle */
  // }, [i18n.language]);

  useEffect(() => {
    if (window.gtag) {
      window.gtag('send', 'page_view', {
        page_path: pathname + search,
      });
    }
  }, [pathname, search]);

  useEffect(() => {
    if (!loggedIn) {
      setLoadingScreen(true);
    }
  }, [loggedIn, setLoadingScreen]);

  useEffect(() => {
    if (!initInProgress) {
      setInitInProgress(true);
      if (token) {
        setLoadingScreen(true);
        getToken({ token })
          .then(refreshMe)
          .then(() => {
            history.replace({
              ...location,
              search: queryString.exclude(search, ['token']),
            });
            setInitInProgress(false);
          });
      } else if (loggedIn && !displayName) {
        setLoadingScreen(true);
        refreshMe().then(() => { setInitInProgress(false); });
      }
    }
  }, [
    initInProgress,
    token,
    getToken,
    history,
    location,
    refreshMe,
    search,
    setLoadingScreen,
    loggedIn,
    displayName,
  ]);

  return (
    <IntlProvider locale={i18n.language}>
      <HelmetProvider>
        <Helmet>
          <title>Tripartie</title>
          <meta name="description" content={t('websiteDescription')} />
        </Helmet>
        <LoadingScreen />
        <Container
          className="root-container d-flex flex-column min-vh-100"
          fluid="md"
        >
          <div className="d-flex min-vh-100">
            {!token && (
              <Switch>
                <Route
                  exact
                  path="/:templateUuid"
                  component={DisputeResolution}
                />
              </Switch>
            )}
          </div>
        </Container>
      </HelmetProvider>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
  me: state.persistent.meReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  getToken: bindActionCreators(getToken, dispatch),
  refreshMe: bindActionCreators(refreshMe, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
