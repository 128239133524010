import React, {
  useCallback,
  useMemo,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import classes from 'assets/style/disputeStep.module.scss';
import Button from 'components/Button';
import DocumentPreview from 'components/DocumentPreview';
import AmountAnswer from 'components/AmountAnswer';
import TextAreaAnswer from 'components/TextAreaAnswer';
import StickyButtons from 'components/StickyButtons';
import { ReactComponent as EditIcon } from 'assets/images/editIcon.svg';
import {
  ACTION_EXIT,
  TYPE_ADD_EVIDENCE,
  TYPE_ADD_ANOTHER_EVIDENCE,
  NS_ADD_EVIDENCE,
  SLUG_ADD_EVIDENCE,
  SLUG_ADD_ANOTHER_EVIDENCE,
  SLUG_ANSWER_ADD_EVIDENCE,
  SLUG_ANSWER_IGNORE_ADD_EVIDENCE,
  ACTION_OPEN_EVIDENCE_FORM,
  TYPE_ACCEPT,
  SLUG_ACCEPT,
  ACTION_OPEN_RETURN_ADDRESS_FORM,
  TYPE_SOLUTION,
  SLUG_SOLUTION,
  NS_SOLUTION,
  SLUG_ANSWER_FULL_REFUND,
  SLUG_ANSWER_PARTIAL_REFUND,
  SLUG_ANSWER_COUNTER_PARTIAL_REFUND,
  SLUG_ANSWER_REJECT,
  SLUG_PARTIAL_REFUND,
  TYPE_PARTIAL_REFUND,
  SLUG_REJECT,
  DEFAULT_PARTIAL_DEFAULT_PERCENT,
  TYPE_TEXTAREA,
  TYPE_REVIEW_AND_SEND,
  NS_REVIEW_AND_SEND,
  SLUG_REVIEW_AND_SEND,
  SELLER,
  SOLUTION_PARTIAL_REFUND,
} from 'helpers/constants';

const MIN_SUBTOTAL_FOR_PARTIAL_REFUND = 500;
const DEFAULT_PARTIAL_REFUND_PERCENT = 10;
const MIN_PARTIAL_REFUND_AMOUNT = 100;

const StandardDisputeStep = ({
  node: {
    slug,
    type,
    ns: nodeNs,
    parentNs,
    answers = [],
    response: rawResponse,
    evidence,
    returnAddress = {},
    options: {
      partialDefaultPercent = DEFAULT_PARTIAL_DEFAULT_PERCENT,
    } = {},
    next,
  } = {},
  me: {
    displayName,
  },
  transaction: {
    subTotal = 0,
    currency = 'EUR',
    messages = [],
    client = '',
  },
  role,
  index,
  currentStep,
  goBack,
  goForward,
  setResponse,
  openEvidenceForm = () => {},
  openReturnAddressForm = () => {},
  openConfirmDeleteEvidenceModal = () => {},
  onDocumentPreviewLoaded = () => {},
  openRecap = () => {},
  exit = () => {},
}) => {
  const ns = useMemo(() => (nodeNs || parentNs || 'base'), [nodeNs, parentNs]);
  const { t: originalT, i18n } = useTranslation(ns);
  const intl = useIntl();

  const {
    proposedSolution: lastProposedSolution,
    proposedRefund: lastProposedRefund,
  } = useMemo(() => (
    messages.length ? messages.slice(-1)[0] : {}
  ), [messages]);

  const partialRefundAmount = useMemo(() => (
    // If there has been a previously proposed refund, it becomes the new starting value
    lastProposedSolution === SOLUTION_PARTIAL_REFUND
      ? lastProposedRefund
      : subTotal * (partialDefaultPercent / 100)
  ), [
    subTotal,
    partialDefaultPercent,
    lastProposedRefund,
    lastProposedSolution,
  ]);

  const maxPartialRefundAmount = useMemo(() => (
    // If there has been a previously proposed refund, it becomes the new max
    lastProposedRefund || subTotal / 2
  ), [
    subTotal,
    lastProposedRefund,
  ]);

  const t = useCallback((path) => (
    originalT(path, {
      client,
      displayName,
      returnAddress: `${returnAddress?.recipientName}, ${returnAddress?.formattedAddress}`,
      partialRefundPercent: intl.formatNumber(partialDefaultPercent / 100, {
        style: 'percent',
      }),
      subTotal: intl.formatNumber(subTotal / 100, {
        style: 'currency',
        currency,
      }),
      partialRefundAmount: intl.formatNumber(partialRefundAmount / 100, {
        style: 'currency',
        currency,
      }),
    })
  ), [
    intl,
    originalT,
    client,
    displayName,
    currency,
    partialDefaultPercent,
    subTotal,
    partialRefundAmount,
    returnAddress,
  ]);

  const roledTSlug = useCallback((path, ns) => {
    const roledPath = path.replace(/(\.|^)([^.]+)$/, `$1${role}_$2`);

    const tSlug = i18n.exists(`${ns}:${roledPath}`)
      ? roledPath
      : path;

    return tSlug;
  }, [i18n, role]);

  const i18nExistsRoled = useCallback((path, ns) => {
    const roledPath = path.replace(/(\.|^)([^.]+)$/, `$1${role}_$2`);

    return i18n.exists(`${ns}:${roledPath}`) || i18n.exists(`${ns}:${path}`);
  }, [i18n, role]);

  const resolveAction = useCallback((action) => {
    switch (action) {
      case ACTION_OPEN_EVIDENCE_FORM:
        return openEvidenceForm;

      case ACTION_OPEN_RETURN_ADDRESS_FORM:
        return openReturnAddressForm;

      case ACTION_EXIT:
        return exit;

      default:
        return false;
    }
  }, [openEvidenceForm, exit, openReturnAddressForm]);

  const renderButtons = useCallback(() => {
    switch (type) {
      case TYPE_PARTIAL_REFUND:
        return (
          <AmountAnswer
            initialAmount={partialRefundAmount}
            max={maxPartialRefundAmount}
            min={MIN_PARTIAL_REFUND_AMOUNT}
            onSubmit={(amount) => {
              setResponse(amount);
              goForward(next);
            }}
          />
        );

      case TYPE_TEXTAREA:
        return (
          <TextAreaAnswer
            placeholder={i18n.exists(`${ns}:${slug}.placeholder`) ? t(`${slug}.placeholder`) : ''}
            onSubmit={(text) => {
              setResponse(text);
              goForward(next);
            }}
          />
        );

      case TYPE_REVIEW_AND_SEND:
        return (
          <Button
            variant="light"
            onClick={openRecap}
          >
            {t(roledTSlug(`${slug}.answers.ok`, ns))}
          </Button>
        );

      default:
        return answers.map(({ slug: buttonSlug, next = {}, action }) => {
          const tSlug = roledTSlug(`${slug}.answers.${buttonSlug}`, ns);

          return (
            <Button
              variant="light"
              key={buttonSlug}
              onClick={() => {
                setResponse(tSlug);
                if (next) {
                  goForward({
                    parentNs: ns,
                    ...next,
                  });
                }

                if (resolveAction(action)) {
                  resolveAction(action)();
                }
              }}
            >
              {t(tSlug)}
            </Button>
          );
        });
    }
  }, [
    answers,
    resolveAction,
    slug,
    type,
    next,
    openRecap,
    goForward,
    i18n,
    maxPartialRefundAmount,
    ns,
    partialRefundAmount,
    roledTSlug,
    setResponse,
    t,
  ]);

  const response = useMemo(() => {
    switch (type) {
      case TYPE_PARTIAL_REFUND:
        return intl.formatNumber(rawResponse / 100, {
          style: 'currency',
          currency,
        });

      case TYPE_TEXTAREA:
        return rawResponse;

      case TYPE_SOLUTION:
        return i18n.exists(`${ns}:${rawResponse}_with_address`)
          ? <Trans t={t} i18nKey={`${rawResponse}_with_address`} />
          : <Trans t={t} i18nKey={rawResponse} />;

      case TYPE_ACCEPT:
        return i18n.exists(`${ns}:${rawResponse}_${lastProposedSolution}`)
          ? <Trans t={t} i18nKey={`${rawResponse}_${lastProposedSolution}`} />
          : <Trans t={t} i18nKey={rawResponse} />;

      default:
        return t(rawResponse);
    }
  }, [intl, i18n, t, ns, type, rawResponse, currency, lastProposedSolution]);

  const showQuestion = useMemo(() => (
    !(type === TYPE_ADD_ANOTHER_EVIDENCE && !!evidence)
    && i18nExistsRoled(`${slug}.title`, ns)
  ), [type, evidence, i18nExistsRoled, slug, ns]);

  const showDescription = useMemo(() => (
    i18nExistsRoled(`${slug}.description`, ns)
  ), [i18nExistsRoled, slug, ns]);

  return (
    <div className={classes.content}>
      {showQuestion && (
        <>
          <p className={classes.title}>
            <Trans t={t} i18nKey={roledTSlug(`${slug}.title`, ns)} />
          </p>
          {showDescription && (
            <p className={classes.description}>
              <Trans t={t} i18nKey={roledTSlug(`${slug}.description`, ns)} />
            </p>
          )}
        </>
      )}
      {index === currentStep ? (
        <StickyButtons className={classes.buttons}>
          {renderButtons()}
        </StickyButtons>
      ) : (
        <>
          {response && !evidence && (
            <div className={classes.response}>
              <div>
                <p>{response}</p>
                {index === currentStep - 1 && (
                  <Button
                    variant="light"
                    onClick={goBack}
                  >
                    <EditIcon />
                  </Button>
                )}
              </div>
            </div>
          )}
          {evidence && (
            <div className={classes.evidence}>
              <DocumentPreview
                file={evidence?.document}
                onEdit={() => {
                  if (index === currentStep - 1) {
                    openEvidenceForm();
                    goBack();
                  }
                }}
                onDelete={index === currentStep - 1 ? openConfirmDeleteEvidenceModal : false}
                onLoaded={onDocumentPreviewLoaded}
              />
              <p>{evidence?.comment}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const DisputeStep = (props = {}) => {
  const {
    node = {},
    transaction: {
      messages = [],
      subTotal,
    } = {},
    role,
  } = props;

  const {
    slug,
    ns: nodeNs,
    parentNs,
    action,
    type,
    next,
    options = {},
    answers = [],
  } = node;

  const {
    noPartialRefund = false,
    partialDefaultPercent: rawPartialDefaultPercent = DEFAULT_PARTIAL_REFUND_PERCENT,
  } = options;

  let newProps;
  const newNext = {
    parentNs: nodeNs || parentNs,
    ...next,
  };

  const {
    proposedSolution: lastProposedSolution,
  } = useMemo(() => (
    messages.length ? messages.slice(-1)[0] : {}
  ), [messages]);

  const partialDefaultPercent = useMemo(() => (
    subTotal * (rawPartialDefaultPercent / 100) < MIN_PARTIAL_REFUND_AMOUNT
      ? Math.ceil((MIN_PARTIAL_REFUND_AMOUNT * 100) / subTotal)
      : rawPartialDefaultPercent
  ), [subTotal, rawPartialDefaultPercent]);

  switch (type) {
    case TYPE_ADD_EVIDENCE:
    case TYPE_ADD_ANOTHER_EVIDENCE:
      newProps = {
        ...props,
        node: {
          ...node,
          slug: slug || SLUG_ADD_EVIDENCE,
          ns: nodeNs || NS_ADD_EVIDENCE,
          action: action || ACTION_OPEN_EVIDENCE_FORM,
          answers: [
            {
              slug: SLUG_ANSWER_ADD_EVIDENCE,
              action: action || ACTION_OPEN_EVIDENCE_FORM,
              next: {
                slug: SLUG_ADD_ANOTHER_EVIDENCE,
                ns: nodeNs,
                parentNs,
                type: TYPE_ADD_ANOTHER_EVIDENCE,
                next: newNext,
              },
            },
            {
              slug: SLUG_ANSWER_IGNORE_ADD_EVIDENCE,
              next: newNext,
            },
          ],
        },
      };
      break;

    case TYPE_SOLUTION: {
      const newAnswers = [];

      if (!messages.length) {
        newAnswers.push({
          slug: SLUG_ANSWER_FULL_REFUND,
          next: newNext,
        });

        if (!noPartialRefund && subTotal >= MIN_SUBTOTAL_FOR_PARTIAL_REFUND) {
          newAnswers.push({
            slug: SLUG_ANSWER_PARTIAL_REFUND,
            next: {
              slug: SLUG_PARTIAL_REFUND,
              ns: nodeNs,
              parentNs,
              type: TYPE_PARTIAL_REFUND,
              next: newNext,
              options: {
                partialDefaultPercent,
              },
            },
          });
        }
      } else if (role === SELLER) {
        newAnswers.push({
          slug: SLUG_ANSWER_REJECT,
          next: {
            slug: SLUG_REJECT,
            ...newNext,
          },
        });

        if (!noPartialRefund && subTotal >= MIN_SUBTOTAL_FOR_PARTIAL_REFUND) {
          newAnswers.push({
            slug: lastProposedSolution === SLUG_ANSWER_PARTIAL_REFUND
              ? SLUG_ANSWER_COUNTER_PARTIAL_REFUND
              : SLUG_ANSWER_PARTIAL_REFUND,
            next: {
              slug: SLUG_PARTIAL_REFUND,
              ns: nodeNs,
              parentNs,
              type: TYPE_PARTIAL_REFUND,
              next: newNext,
              options: {
                partialDefaultPercent,
              },
            },
          });
        }

        if (lastProposedSolution === SLUG_ANSWER_PARTIAL_REFUND) {
          newAnswers.push({
            slug: SLUG_ANSWER_FULL_REFUND,
            action: ACTION_OPEN_RETURN_ADDRESS_FORM,
            next: newNext,
          });
        }
      }

      newProps = {
        ...props,
        node: {
          ...node,
          slug: slug || SLUG_SOLUTION,
          ns: nodeNs || NS_SOLUTION,
          answers: newAnswers,
        },
      };
      break;
    }

    case TYPE_PARTIAL_REFUND:
      newProps = {
        ...props,
        node: {
          ...node,
          ns: nodeNs || NS_SOLUTION,
        },
      };
      break;

    case TYPE_REVIEW_AND_SEND:
      newProps = {
        ...props,
        node: {
          ...node,
          slug: slug || SLUG_REVIEW_AND_SEND,
          ns: nodeNs || NS_REVIEW_AND_SEND,
        },
      };
      break;

    case TYPE_ACCEPT:
      /* eslint-disable no-case-declarations */
      const acceptAnswer = answers.find(({ slug }) => (slug === SLUG_ACCEPT)) || { slug: SLUG_ACCEPT };
      const otherAnswers = answers.filter(({ slug }) => (slug !== SLUG_ACCEPT));
      /* eslint-enable no-case-declarations */

      if (lastProposedSolution === SLUG_ANSWER_FULL_REFUND) {
        acceptAnswer.action = ACTION_OPEN_RETURN_ADDRESS_FORM;
      }

      newProps = {
        ...props,
        node: {
          ...node,
          answers: [
            ...otherAnswers,
            acceptAnswer,
          ],
        },
      };
      break;

    default:
      newProps = props;
  }

  return StandardDisputeStep(newProps);
};

export default DisputeStep;
