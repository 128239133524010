import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'components/Button';
import classes from 'assets/style/simpleConfirmModal.module.scss';

const SimpleConfirmModal = ({
  show,
  onConfirm,
  onCancel,
  onHide,
  image,
  title,
  text,
  confirmButtonLabel,
  cancelButtonLabel,
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
    contentClassName={classes.content}
  >
    {image ? <div className={classes.image}>{image}</div> : <div />}
    {title ? (
      <Modal.Header>
        <p className={classes.title}>{title}</p>
      </Modal.Header>
    ) : (
      <div />
    )}
    <Modal.Body>
      <div className={classes.body}>
        <p className={classes.text}>{text}</p>
        <Button
          variant="primary"
          className={classes.button}
          onClick={onConfirm}
        >
          {confirmButtonLabel}
        </Button>
        <Button
          variant="light"
          className={classes.button}
          onClick={onCancel}
        >
          {cancelButtonLabel}
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

SimpleConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onHide: PropTypes.func,
  image: PropTypes.element,
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
};

SimpleConfirmModal.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  onHide: () => {},
  image: null,
  title: '',
  text: '',
};

export default SimpleConfirmModal;
