import { envName, isDev } from 'helpers/env';

export const INITIATOR = 'initiator';
export const BUYER = 'buyer';
export const SELLER = 'seller';

export const LANG_COOKIE_NAME = `tripartie-lang-${envName}`;
export const LANG_COOKIE_OPT = {
  path: '/',
  domain: isDev ? 'localhost' : 'tripartie.app',
};

export const ISSUE_PROOFS_FILE_FORMATS = [
  'image/png',
  'image/jpeg',
  'application/pdf',
];

export const MISSING_REFRESH_TOKEN = 'missing_refresh_token';

export const TYPE_START = 'start';
export const TYPE_ISSUE_TYPE = 'issue_type';

export const BASE_NS = 'base';

export const ACTION_EXIT = 'exit';

export const TYPE_ADD_EVIDENCE = 'add_evidence';
export const TYPE_ADD_ANOTHER_EVIDENCE = 'add_another_evidence';
export const NS_ADD_EVIDENCE = 'add_evidence';
export const SLUG_ADD_EVIDENCE = 'add_evidence';
export const SLUG_ADD_ANOTHER_EVIDENCE = 'add_another_evidence';
export const SLUG_ANSWER_ADD_EVIDENCE = 'add';
export const SLUG_ANSWER_IGNORE_ADD_EVIDENCE = 'ignore';
export const ACTION_OPEN_EVIDENCE_FORM = 'open_evidence_form';

export const TYPE_ACCEPT = 'accept';
export const SLUG_ACCEPT = 'accept';
export const ACTION_OPEN_RETURN_ADDRESS_FORM = 'open_return_address_form';

export const TYPE_BUYER_RESPONSE = 'buyer_response';
export const RESPONSE_BUYER_REJECT = 'buyer_reject';

export const TYPE_SOLUTION = 'solution';
export const SLUG_SOLUTION = 'solution';
export const NS_SOLUTION = 'solution';
export const SLUG_ANSWER_FULL_REFUND = 'full_refund';
export const SLUG_ANSWER_PARTIAL_REFUND = 'partial_refund';
export const SLUG_ANSWER_COUNTER_PARTIAL_REFUND = 'counter_partial_refund';
export const SLUG_ANSWER_REJECT = 'reject';
export const RESPONSE_ACCEPT = 'accept';
export const RESPONSE_REJECT = 'reject';
export const RESPONSE_ACCEPT_REGEXP = `^((${BUYER}|${SELLER})_)?${RESPONSE_ACCEPT}$`;
export const RESPONSE_REJECT_REGEXP = `^((${BUYER}|${SELLER})_)?${RESPONSE_REJECT}$`;

export const SOLUTION_PARTIAL_REFUND = 'partial_refund';
export const SOLUTION_FULL_REFUND = 'full_refund';

export const SLUG_PARTIAL_REFUND = 'partial_refund';
export const TYPE_PARTIAL_REFUND = 'partial_refund';
export const DEFAULT_PARTIAL_DEFAULT_PERCENT = 8;

export const SLUG_REJECT = 'reject';

export const TYPE_TEXTAREA = 'textarea';

export const TYPE_REVIEW_AND_SEND = 'review_and_send';
export const NS_REVIEW_AND_SEND = 'review_and_send';
export const SLUG_REVIEW_AND_SEND = 'review_and_send';

export const STATUS_MEDIATION = 'mediation';
export const STATUS_RETURN = 'return';
export const STATUS_RETURN_SHIPPED = 'return_shipped';
export const STATUS_RETURN_DELIVERED = 'return_delivered';
export const STATUS_ARBITRATION = 'arbitration';
export const STATUS_CLOSED = 'closed';
export const STATUS_CANCEL = 'cancel';

export const PENDING_STATUSES = [
  STATUS_MEDIATION,
  STATUS_RETURN,
  STATUS_RETURN_SHIPPED,
  STATUS_RETURN_DELIVERED,
  STATUS_ARBITRATION,
];
export const ENDED_STATUSES = [
  STATUS_CLOSED,
  STATUS_CANCEL,
];
