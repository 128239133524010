const countriesMapper = (allCountries) => {
  const slugs = [];
  const isoCodes = {};
  const slugFromIso = {};

  allCountries.forEach(({ slug, iso2 }) => {
    slugs.push(slug);
    isoCodes[slug] = iso2;
    slugFromIso[iso2] = slug;
  });

  return {
    slugs,
    isoCodes,
    slugFromIso,
  };
};

export default countriesMapper;
