import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { debug, hash } from 'helpers/env';

const SUPPORTED_LNGS = [
  'en',
  'fr',
  // 'de',
];

const SUPPORTED_LNGS_DEV = [
  ...SUPPORTED_LNGS,
  // 'es',
];

// Every time a new namespace is loaded, the app will flicker
// So it's better to load everything at init
const NS = [
  'accept',
  'add_evidence',
  'amount_answer',
  'base',
  'damaged',
  'dispute_message',
  'dispute_recap',
  'does_not_fit',
  'does_not_match',
  'does_not_work',
  'fraud',
  'incomplete',
  'review_and_send',
  'seller_contest',
  'solution',
  'success_screen',
  'textarea_answer',
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: debug ? SUPPORTED_LNGS_DEV : SUPPORTED_LNGS,
    fallbackLng: /* debug ? false : */ 'en',
    debug,
    appendNamespaceToMissingKey: !!debug,
    parseMissingKeyHandler: (key) => (debug ? `[[${key}]]` : key),
    ns: NS,

    backend: {
      queryStringParams: { hash },
    },

    react: {
      transKeepBasicHtmlNodesFor: ['strong', 'em'],
    },
  });

export default i18n;
