/* eslint-disable import/no-unused-modules */
export const LOAD_SINGLE_MEDIA = 'medias/LOAD_SINGLE_MEDIA';
export const FETCH_MEDIA_FILE = 'medias/FETCH_MEDIA_FILE';
/* eslint-enable import/no-unused-modules */

export const loadSingleMedia = (file, comment) => {
  const request = new FormData();
  request.append('media', file);
  request.append('comment', comment);

  return {
    type: LOAD_SINGLE_MEDIA,
    payload: {
      request: {
        method: 'POST',
        url: '/api/media/create-single/',
        data: request,
      },
    },
  };
};

export const fetchMediaFile = (id) => ({
  type: FETCH_MEDIA_FILE,
  payload: {
    request: {
      responseType: 'blob',
      method: 'GET',
      url: `/api/media/${id}/`,
    },
  },
});
