import React, {
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/successScreen.module.scss';
import Button from 'components/Button';
import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';
import {
  BUYER,
  INITIATOR,
} from 'helpers/constants';

const SuccessScreen = ({
  role: rawRole,
  messageCount = 0,
  returnUrl,
}) => {
  const { t, i18n } = useTranslation('success_screen');

  const role = useMemo(() => (
    rawRole === BUYER && messageCount === 1 ? INITIATOR : BUYER
  ), [rawRole, messageCount]);

  const tRoled = useCallback((path) => {
    const roledPath = path.replace(/(\.|^)([^.]+)$/, `$1${role}_$2`);

    const tSlug = i18n.exists(`success_screen:${roledPath}`)
      ? roledPath
      : path;

    return t(tSlug);
  }, [i18n, t, role]);

  return (
    <div className={classes.container}>
      <SuccessIcon className={classes.icon} />
      <p className={classes.title}>{tRoled('title')}</p>
      <p className={classes.text}>{tRoled('text')}</p>
      <Button
        className={classes.submit}
        onClick={() => {
          if (returnUrl) {
            window.location.href = returnUrl;
          }
        }}
      >
        {t('submit')}
      </Button>
    </div>
  );
};

export default SuccessScreen;
