import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classes from 'assets/style/disputeMessage.module.scss';
import { ReactComponent as SummaryOrderIcon } from 'assets/images/summary-order.svg';
import { ReactComponent as SummaryIssueIcon } from 'assets/images/summary-issue.svg';
import DocumentPreview from 'components/DocumentPreview';
import cx from 'classnames';
import { fetchMediaFile } from 'logic/actions/mediaActions';
import { cleanResponseSlug } from 'helpers/cleanSlugs';
import {
  BASE_NS,
  RESPONSE_ACCEPT_REGEXP,
  SOLUTION_PARTIAL_REFUND,
  BUYER,
  SELLER,
  TYPE_TEXTAREA,
} from 'helpers/constants';

const OWN = 'own';
const REQUEST = 'request';
const RESPONSE = 'response';

const DisputeMessage = ({
  isRecap,
  transaction: {
    title,
    currency = 'EUR',
    subTotal,
    issueType,
    buyerUserId,
    sellerUserId,
  } = {},
  message: {
    proposedSolution = '',
    response = '',
    proposedRefund = 0,
    medias: rawMedias = [],
    data = [],
    authorUserId,
  } = {},
  index,
  role,
  fetchMediaFile,
}) => {
  const intl = useIntl();

  const authorRole = useMemo(() => {
    if (authorUserId) {
      if (authorUserId === buyerUserId) {
        return BUYER;
      }
      if (authorUserId === sellerUserId) {
        return SELLER;
      }
    }
    return role;
  }, [
    buyerUserId,
    sellerUserId,
    authorUserId,
    role,
  ]);

  const [previousRawMedias, setPreviousRawMedias] = useState([]);
  const [medias, setMedias] = useState([]);

  useEffect(() => {
    if (rawMedias.reduce((acc, { media }) => (acc && !!media), true)) {
      // We are in recap, so medias have not been sent yet
      setMedias(rawMedias);
    } else if (!isEqual(rawMedias, previousRawMedias)) {
      setPreviousRawMedias(rawMedias);
      setMedias([]);
      rawMedias.forEach(({ id, comment }) => (
        fetchMediaFile(id)
          .then(({ payload: { data: media } = {} }) => {
            setMedias((prev) => [...prev, { id, media, comment }]);
          })
          .catch(() => {})
      ));
    }
  }, [rawMedias, fetchMediaFile, previousRawMedias]);

  const isRequest = useMemo(() => (index === 0), [index]);

  const formattedDiscount = useMemo(() => (
    intl.formatNumber(proposedRefund / 100, {
      style: 'currency',
      currency,
    })
  ), [intl, proposedRefund, currency]);

  const formattedSubTotal = useMemo(() => (
    intl.formatNumber(subTotal / 100, {
      style: 'currency',
      currency,
    })
  ), [intl, subTotal, currency]);

  const formattedNewTotal = useMemo(() => (
    intl.formatNumber((subTotal - proposedRefund) / 100, {
      style: 'currency',
      currency,
    })
  ), [intl, proposedRefund, subTotal, currency]);

  const nsArray = useMemo(() => (data.filter(({ ns }) => (!!ns)).map(({ ns }) => (ns))), [data]);

  const { t: originalT, i18n } = useTranslation(['dispute_message', ...nsArray]);

  const t = useCallback((path) => (
    originalT(path, {
      discount: formattedDiscount,
      subTotal: formattedSubTotal,
    })
  ), [
    originalT,
    formattedDiscount,
    formattedSubTotal,
  ]);

  const tRoled = useCallback((path, ns) => {
    const roledPath = path.replace(/(\.|^)([^.]+)$/, `$1${role}_$2`);

    return i18n.exists(`${ns}:${roledPath}`)
      ? t(roledPath)
      : t(path);
  }, [i18n, t, role]);

  const solutionToDisplay = useMemo(() => (
    response?.match(RESPONSE_ACCEPT_REGEXP)
      ? {
        title: tRoled(`proposition.${response}.title`, 'dispute_message'),
        description: tRoled(`proposition.${proposedSolution}.title`, 'dispute_message'),
      }
      : {
        title: tRoled(`proposition.${proposedSolution || response}.title`, 'dispute_message'),
        description: tRoled(`proposition.${proposedSolution || response}.description`, 'dispute_message'),
      }
  ), [
    response,
    proposedSolution,
    tRoled,
  ]);

  return (
    <div className={cx(classes.container, { [classes.recap]: isRecap, [classes.isBuyer]: role === BUYER })}>
      {(proposedSolution || response) && (
        <div className={cx(classes.issueType, { [classes.recap]: isRecap })}>
          <p className={classes.label}>
            {t(`proposition.label.${role === authorRole ? OWN : authorRole}.${isRequest ? REQUEST : RESPONSE}`)}
          </p>
          <p className={classes.title}>
            {solutionToDisplay.title}
          </p>
          <p className={classes.description}>
            {solutionToDisplay.description}
          </p>
        </div>
      )}
      <div className={classes.summary}>
        <div className={classes.line}>
          <div className={classes.icon}><SummaryOrderIcon /></div>
          <p>
            <span className={classes.title}>{t('summary.order')}</span>
            <span className={classes.value}>{title}</span>
          </p>
        </div>
        <div className={classes.line}>
          <div className={classes.icon}><SummaryIssueIcon /></div>
          <p>
            <span className={classes.title}>{t('summary.issue')}</span>
            <span className={classes.value}>{t(`summary.${issueType}`)}</span>
          </p>
        </div>
      </div>
      {!!data?.length && (
        <div className={classes.data}>
          {data.map(({
            type,
            slug,
            response,
            ns,
            parentNs,
          }) => (
            <div
              className={classes.line}
              key={slug}
            >
              <p className={classes.title}>{t(`${ns || parentNs || BASE_NS}:${slug}.title`)}</p>
              {type === TYPE_TEXTAREA ? (
                <p className={classes.value}>{response}</p>
              ) : (
                <p className={classes.value}>{t(`${ns || parentNs || BASE_NS}:${slug}.answers.${cleanResponseSlug(response)}`)}</p>
              )}
            </div>
          ))}
        </div>
      )}
      {!!medias.length && (
        <div className={classes.medias}>
          {medias.map(({ id, media, comment } = {}) => (
            <div
              className={classes.media}
              key={id}
            >
              <DocumentPreview id={id} file={media} />
              <p>{comment}</p>
            </div>
          ))}
        </div>
      )}
      {proposedSolution.match(SOLUTION_PARTIAL_REFUND) && (
        <div className={classes.totals}>
          <div className={classes.line}>
            <p className={classes.title}>{t('totals.subTotal')}</p>
            <p className={classes.value}>{formattedSubTotal}</p>
          </div>
          <div className={classes.line}>
            <p className={classes.title}>{t('totals.discount')}</p>
            <p className={classes.value}>
              <span className="mr-1">-</span>
              {formattedDiscount}
            </p>
          </div>
          <div className={classes.line}>
            <p className={classes.title}>{t('totals.newTotal')}</p>
            <p className={classes.value}>{formattedNewTotal}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchMediaFile: bindActionCreators(fetchMediaFile, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(DisputeMessage);
