import React from 'react';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import classes from 'assets/style/trackingNumberForm.module.scss';
import cx from 'classnames';

const SHIPPING_PROVIDERS = [
  {
    label: 'Colissimo',
    value: 'colissimo',
  },
  {
    label: 'TNT',
    value: 'tnt',
  },
  {
    label: 'Chronopost',
    value: 'chronopost-france',
  },
  {
    label: 'Swiss Post',
    value: 'swiss-post',
  },
  {
    label: 'DPD',
    value: 'dpd',
  },
  {
    label: 'UPS',
    value: 'ups',
  },
  {
    label: 'DHL',
    value: 'dhl',
  },
  {
    label: 'FedEx',
    value: 'fedex',
  },
];

const TrackingNumberForm = ({
  startTracking,
}) => {
  const { t } = useTranslation('tracking_number_form');

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        shippingProvider: yup.string().required(t('empty_shipping_provider')),
        trackingNumber: yup.string().required(t('empty_tracking_number')),
      })}
      onSubmit={startTracking}
      initialValues={{
        shippingProvider: '',
        trackingNumber: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className={classes.container}>
          <p className={classes.title}>{t('title')}</p>
          <p className={classes.text}>{t('text')}</p>
          <Form.Group
            controlId="shippingProvider"
            className={classes.formGroup}
          >
            <Form.Label className={classes.label}>
              {t('carrier')}
            </Form.Label>
            <Form.Control
              className={cx(classes.input, {
                [classes.selectPlaceholder]: !values.shippingProvider,
              })}
              as="select"
              name="shippingProvider"
              value={values.shippingProvider}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={
                touched.shippingProvider && !!errors.shippingProvider
              }
              autoFocus
            >
              <option value="" disabled>
                {t('carrier_placeholder')}
              </option>
              {SHIPPING_PROVIDERS.map((provider) => (
                <option key={provider.value} value={provider.value}>
                  {provider.label}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.shippingProvider}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            controlId="trackingNumber"
            className={classes.formGroup}
          >
            <Form.Label className={classes.label}>
              {t('tracking_number')}
            </Form.Label>
            <Form.Control
              className={classes.input}
              placeholder={t('tracking_number_placeholder')}
              name="trackingNumber"
              value={values.trackingNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={
                touched.trackingNumber && !!errors.trackingNumber
              }
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              {errors.trackingNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            type="submit"
            isLoading={isSubmitting}
          >
            {t('submit')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default TrackingNumberForm;
