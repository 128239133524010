import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import AddressField from 'components/AddressField';
import ManualAddressForm from 'components/ManualAddressForm';
import classes from 'assets/style/returnAddressForm.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountries,
  selectAllCountries,
} from 'logic/actions/countriesActions';
import { trimObjectValues } from 'helpers/stringHelpers';
import validateGoogleAddress from 'helpers/validateGoogleAddress';
import countriesMapper from 'helpers/countriesMapper';

const ReturnAddressForm = ({
  returnDeliveryAddress = {},
  setReturnAddress,
}) => {
  const { t } = useTranslation('return_address_form');

  const dispatch = useDispatch();

  const [isValidateGoogleAddress, setIsValidateGoogleAddress] = useState(false);

  const allCountries = useSelector(selectAllCountries);

  const { isoCodes } = useMemo(() => (countriesMapper(allCountries)), [allCountries]);

  const { validateCountry, validateFields, validateEmptyCountry } = useMemo(
    () => validateGoogleAddress(isoCodes),
    [isoCodes],
  );

  useEffect(() => {
    if (!allCountries.length) {
      dispatch(getCountries());
    }
  }, [allCountries.length, dispatch]);

  const validationSchema = yup.object({
    recipientName: yup
      .string()
      .trim()
      .required(t('required_field')),
    address: yup.mixed().when('isManualAddress', {
      is: false,
      then: yup
        .mixed()
        .test({
          name: 'address',
          message: t('required_field'),
          test: validateEmptyCountry,
          exclusive: false,
        })
        .test({
          name: 'address',
          message: t('invalid_country'),
          test: validateCountry,
          exclusive: false,
        })
        .test({
          name: 'address',
          message: t('invalid_address'),
          test: validateFields,
          exclusive: false,
        }),
    }),
    isManualAddress: yup.boolean(),
    manualAddress: yup.mixed().when('isManualAddress', {
      is: true,
      then: yup.object({
        addressLine1: yup
          .string()
          .trim()
          .min(5, t('min_error'))
          .max(95, t('max_error'))
          .required(t('required_field')),
        addressLine2: yup
          .string()
          .min(5, t('min_error'))
          .max(95, t('max_error')),
        city: yup
          .string()
          .trim()
          .min(2, t('min_error'))
          .max(35, t('max_error'))
          .required(t('required_field')),
        postcode: yup
          .string()
          .trim()
          .min(2, t('min_error'))
          .max(10, t('max_error'))
          .required(t('required_field')),
        countryIso2: yup.string().required(t('required_field')),
        country: yup.string().required(t('required_field')),
      }),
    }),
  });

  const handleSubmit = useCallback(
    ({
      isManualAddress,
      manualAddress,
      recipientName,
      address,
    }) => {
      if (isManualAddress) {
        const {
          addressLine1,
          addressLine2,
          city,
          postcode,
          country,
          countryIso2,
        } = trimObjectValues(manualAddress);

        setReturnAddress({
          addressLine1,
          addressLine2,
          city,
          postcode,
          recipientName: recipientName.trim(),
          countryIso2,
          country,
          isManualAddress,
          formattedAddress: `${addressLine1},${addressLine2
            ? ` ${addressLine2},` : ''} ${postcode} ${city}, ${country}`,
        });
      } else {
        setReturnAddress({
          ...address,
          recipientName: recipientName.trim(),
          isManualAddress,
        });
      }
    },
    [setReturnAddress],
  );

  const initialValues = {
    recipientName: returnDeliveryAddress?.recipientName || '',
    address: { ...returnDeliveryAddress },
    isManualAddress: returnDeliveryAddress?.isManualAddress || false,
    manualAddress: {
      addressLine1: returnDeliveryAddress?.addressLine1 || '',
      addressLine2: returnDeliveryAddress?.addressLine2 || '',
      city: returnDeliveryAddress?.city || '',
      postcode: returnDeliveryAddress?.postcode || '',
      country: returnDeliveryAddress?.country || '',
      countryIso2: returnDeliveryAddress?.countryIso2 || '',
    },
  };

  return (
    <div className={classes.container}>
      <p className={classes.title}>{t('title')}</p>
      <p className={classes.text}>{t('text')}</p>

      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          errors,
        }) => {
          const { recipientName, isManualAddress } = values;

          const isManualFormVisible = isManualAddress;

          return (
            <Form onSubmit={handleSubmit}>
              <div>
                <Form.Group
                  controlId="recipientName"
                  className={classes.formGroup}
                >
                  <Form.Label className={classes.label}>{t('recipient_name')}</Form.Label>
                  <Form.Control
                    name="recipientName"
                    className={classes.input}
                    value={recipientName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.recipientName && !!errors.recipientName}
                    placeholder={t('recipient_name_placeholder')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.recipientName}
                  </Form.Control.Feedback>
                </Form.Group>
                {isManualFormVisible ? (
                  <ManualAddressForm
                    isValidateGoogleAddress={isValidateGoogleAddress}
                    setIsValidateGoogleAddress={setIsValidateGoogleAddress}
                    allowedCountries={Object.values(isoCodes)}
                  />
                ) : (
                  <>
                    <Form.Group
                      controlId="address"
                      className={classes.formGroup}
                    >
                      <Form.Label className={classes.label}>{t('address')}</Form.Label>
                      <Form.Control
                        className={classes.input}
                        as={AddressField}
                        type="text"
                        name="address"
                        onChange={(value) => {
                          setFieldValue('address', value);
                        }}
                        onBlur={handleBlur}
                        isInvalid={!!errors.address}
                        placeholder={t('address_placeholder')}
                        formattedAddress={returnDeliveryAddress?.formattedAddress}
                        isValidateGoogleAddress={isValidateGoogleAddress}
                      />
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button
                      className={classes.manualAddressToggle}
                      variant="link"
                      onClick={() => {
                        setFieldValue('isManualAddress', true);
                      }}
                    >
                      {t('manual_address_toggle')}
                    </Button>
                  </>
                )}

                <Button
                  type="submit"
                  className={classes.button}
                >
                  {t('submit')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ReturnAddressForm;
