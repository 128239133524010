import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import 'assets/style/addressField.scss';
import colors from 'assets/style/_colors.module.scss';
import closeIcon from 'assets/images/close-cross.svg';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

const STREET_NUMBER = 'street_number';
const STREET = 'route';
const CITY = 'locality';
const POSTCODE = 'postal_code';
const COUNTRY = 'country';

const AddressField = ({
  formattedAddress,
  onChange,
  placeholder,
  isValidateGoogleAddress,
}) => {
  const {
    setFieldValue, values, errors, setFieldError,
  } = useFormikContext();

  const { i18n, t } = useTranslation('return_address_form');

  const currentLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';
  const [rawAddress, setRawAddress] = useState(
    formattedAddress
      ? {
        label: formattedAddress,
        value: {},
      }
      : null,
  );

  useEffect(() => {
    if (values.isManualAddress && !isValidateGoogleAddress) {
      setRawAddress(null);
      setFieldValue('address', null);
    }
  }, [values.isManualAddress, isValidateGoogleAddress, setFieldValue]);

  const formatAddressLine1 = useCallback(
    (streetNumber, street, countryIso2) => {
      switch (countryIso2) {
        case 'CH':
          return `${street} ${streetNumber}`.trim();
          // break;

        default:
          return `${streetNumber} ${street}`.trim();
      }
    },
    [],
  );

  const Menu = ({ className = '', ...props }) => (
    <components.Menu {...props} className={`mt-0 ${className}`} />
  );

  const ClearIndicator = ({
    innerProps: { onTouchEnd, onMouseDown, ...restProps },
  }) => {
    const handleTouchEnd = useCallback((e) => {
      onTouchEnd(e);
      setFieldError('address', '');
    }, [onTouchEnd]);

    const handleMouseDown = useCallback((e) => {
      onMouseDown(e);
      setFieldError('address', '');
    }, [onMouseDown]);

    return (
      <div
        className="addressField__clear-indicator"
        role="button"
        tabIndex={0}
        {...restProps}
        onMouseDown={handleMouseDown}
        onTouchEnd={handleTouchEnd}
      >
        <img src={closeIcon} alt="clear address" />
      </div>
    );
  };

  const customStyles = useMemo(
    () => (errors.address
      ? {
        control: (provided, state) => ({
          ...provided,
          borderColor: `${colors.errorColor} !important`,
          boxShadow: state.isFocused
            ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important'
            : '',
        }),
      }
      : {}),
    [errors.address],
  );

  const handleLoadingFail = useCallback(() => {
    toast.error(t('googleMapsError'));
    setFieldValue('isManualAddress', true);
  }, [t, setFieldValue]);

  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyBoNf0OFGpdt3Gh1Qd4Y2h217JA4D-98aE"
      apiOptions={{ language: currentLang, region: currentLang }}
      minLengthAutocomplete={3}
      onLoadFailed={handleLoadingFail}
      selectProps={{
        isDisabled: values.isManualAddress === true,
        classNamePrefix: 'addressField',
        value: rawAddress,
        placeholder,
        isClearable: true,
        noOptionsMessage: () => null,
        components: {
          Menu,
          ClearIndicator,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        },
        styles: customStyles,
        onChange: (data) => {
          setRawAddress(data);
          const { value: { place_id: placeId } = {} } = data || {};

          if (placeId) {
            geocodeByPlaceId(placeId)
              .then(
                ([
                  {
                    address_components: addressComponents,
                    formatted_address: formattedAddress,
                  } = {},
                ]) => {
                  let streetNumber = '';
                  let street = '';
                  let city = '';
                  let postcode = '';
                  let countryIso2 = '';
                  let country = '';

                  addressComponents.forEach((item) => {
                    if (item.types.includes(STREET_NUMBER)) {
                      streetNumber = item.long_name;
                    }
                    if (item.types.includes(STREET)) {
                      street = item.long_name;
                    }
                    if (item.types.includes(CITY)) {
                      city = item.long_name;
                    }
                    if (item.types.includes(POSTCODE)) {
                      postcode = item.long_name;
                    }
                    if (item.types.includes(COUNTRY)) {
                      countryIso2 = item.short_name;
                      country = item.long_name;
                    }
                  });
                  const values = {
                    addressLine1: formatAddressLine1(
                      streetNumber,
                      street,
                      countryIso2,
                    ),
                    city,
                    postcode,
                    countryIso2,
                    country,
                    formattedAddress,
                  };
                  onChange(values);
                },
              )
              .catch(() => {
                onChange(null);
              });
          } else {
            onChange(null);
          }
        },
      }}
    />
  );
};

export default AddressField;
