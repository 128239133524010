/* eslint-disable import/no-unused-modules */
export const GET_TRANSACTION_BY_UUID = 'transactions/GET_TRANSACTION_BY_UUID';
export const OPEN_ISSUE = 'transactions/OPEN_ISSUE';
export const UPDATE_ISSUE = 'transactions/UPDATE_ISSUE';
export const SET_RETURN_ADDRESS = 'transactions/SET_RETURN_ADDRESS';
export const SHIP_TRANSACTION = 'transactions/SHIP_TRANSACTION';
export const START_ARBITRATION = 'transactions/START_ARBITRATION';
export const CLOSE_ISSUE = 'transactions/CLOSE_ISSUE';
/* eslint-enable import/no-unused-modules */

export const getTransactionByUuid = (uuid) => ({
  type: GET_TRANSACTION_BY_UUID,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dispute/transactions/${uuid}/`,
    },
  },
});

export const openIssue = (transactionId, data) => ({
  type: OPEN_ISSUE,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dispute/transactions/${transactionId}/open-issue/`,
      data,
    },
  },
});

export const updateIssue = (transactionId, data) => ({
  type: UPDATE_ISSUE,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dispute/transactions/${transactionId}/update-issue/`,
      data,
    },
  },
});

export const setReturnAddress = (transactionId, returnAddress) => ({
  type: SET_RETURN_ADDRESS,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dispute/transactions/${transactionId}/return-address/`,
      data: returnAddress,
    },
  },
});

export const shipTransaction = (transactionId, data) => ({
  type: SHIP_TRANSACTION,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dispute/transactions/${transactionId}/ship/`,
      data,
    },
  },
});

export const startArbitration = (transactionId) => ({
  type: START_ARBITRATION,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dispute/transactions/${transactionId}/arbitration/`,
    },
  },
});

export const closeIssue = (transactionId) => ({
  type: CLOSE_ISSUE,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dispute/transactions/${transactionId}/close/`,
    },
  },
});

export const selectCurrentTransactionsCount = (state) => state.persistent.transactionsReducer.activeTransactions.count;
