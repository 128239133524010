import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistCombineReducers } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from 'redux-state-sync';
import axiosMiddleware from 'redux-axios-middleware';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import deepfilter from 'deep-filter';
import storage from 'redux-persist/lib/storage';
import { persistentReducers, volatileReducers } from 'logic/reducers';
import { apiUrl, isDev } from 'helpers/env';
import { MISSING_REFRESH_TOKEN } from 'helpers/constants';
import {
  GET_TOKEN,
  REFRESH_TOKEN,
  refreshToken,
} from 'logic/actions/authActions';

const persistConfig = {
  key: 'persistent',
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistentReducer = persistCombineReducers(
  persistConfig,
  persistentReducers,
);
const volatileReducer = combineReducers(volatileReducers);
const rootReducer = combineReducers({
  persistent: persistentReducer,
  volatile: volatileReducer,
});

const client = axios.create({
  baseURL: apiUrl,
  responseType: 'json',
});

let refreshTokenPromise = null;

const axiosMiddlewareOptions = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      async ({ getState, getSourceAction, dispatch }, request) => {
        const { noAuth = false, type: sourceActionType } = getSourceAction(request);

        const expiresAt = getState()?.volatile?.authReducer?.expiresAt || 0;
        let accessToken = getState()?.volatile?.authReducer?.accessToken;
        const currentTimestamp = Date.now() / 1000;

        if (
          sourceActionType !== GET_TOKEN
          && sourceActionType !== REFRESH_TOKEN
          && !noAuth
          && (!accessToken || expiresAt < currentTimestamp)
        ) {
          if (!refreshTokenPromise) {
            refreshTokenPromise = dispatch(refreshToken()).finally(() => {
              refreshTokenPromise = null;
            });
          }
          try {
            await refreshTokenPromise;
            accessToken = getState()?.volatile?.authReducer?.accessToken;
          } catch (e) {
            /* continue regardless of error */
          }
        }

        if (!noAuth && accessToken) {
          request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return request;
      },
    ],
    response: [
      {
        success: (c, res) => {
          // Filter out null values to allow for simplier destructuring (null values become undefined, so default values are used instead)
          res.data = deepfilter(res.data, (v) => v !== null);

          return Promise.resolve(res);
        },
        error: ({ getSourceAction }, error = {}) => {
          const {
            response: {
              status: responseStatus = 0,
              data: responseData = {},
            } = {},
            config: { method: requestMethod = '', url: requestUrl = '' } = {},
          } = error;

          const {
            errors: {
              retryUrl,
              returnUrl,
            } = {},
          } = responseData;

          const { type: sourceActionType } = getSourceAction(error.config);

          let errorMessage = `${requestMethod.toUpperCase()} ${requestUrl}: `;
          if (responseStatus) {
            if (responseData) {
              errorMessage += `${responseStatus} error from backend with data: ${JSON.stringify(
                responseData,
              )}`;
            } else {
              errorMessage += `${responseStatus} error`;
            }
          } else {
            errorMessage += 'No response from backend';
          }

          if (isDev) {
            console.error(errorMessage); // eslint-disable-line no-console
          }

          switch (responseStatus) {
            case 429:
              /* eslint-disable no-alert */
              alert(
                'Tu as effectué trop de tentatives. Attends quelques minutes avant d\'essayer à nouveau.',
              );
              /* eslint-enable no-alert */
              break;

            case 401:
              if (retryUrl) {
                window.location.href = retryUrl;
              } else if (returnUrl) {
                window.location.href = returnUrl;
              }
              break;

            case 400:
              if (
                sourceActionType === REFRESH_TOKEN
                && responseData?.errors === MISSING_REFRESH_TOKEN
              ) {
                return Promise.reject(error);
              }
              Sentry.captureException(new Error(errorMessage));
              break;

            case 0:
              break;

            default:
              Sentry.captureException(new Error(errorMessage));
          }

          return Promise.reject(error);
        },
      },
    ],
  },
};

const syncConfig = {
  predicate: ({ type }) => type.startsWith('sync/'),
};

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      axiosMiddleware(client, axiosMiddlewareOptions),
      createStateSyncMiddleware(syncConfig),
    ),
  ),
);

initStateWithPrevTab(store);

const persistor = persistStore(store);

export { persistor, store };
