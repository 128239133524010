import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { bindActionCreators } from 'redux';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import {
  useIntl,
  FormattedDate,
  FormattedTime,
} from 'react-intl';
import { useLoadingScreen } from 'helpers/LoadingScreenContext';
import clientCssGenerator from 'helpers/clientCssGenerator';
import classes from 'assets/style/disputeResolution.module.scss';
import DisputeRecap from 'components/DisputeRecap';
import DisputeStep from 'components/DisputeStep';
import DisputeMessage from 'components/DisputeMessage';
import Button from 'components/Button';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import AddEvidenceForm from 'components/AddEvidenceForm';
import ReturnAddressForm from 'components/ReturnAddressForm';
import TrackingNumberForm from 'components/TrackingNumberForm';
import SuccessScreen from 'components/SuccessScreen';
import SimpleConfirmModal from 'components/SimpleConfirmModal';
import StickyButtons from 'components/StickyButtons';
import {
  openIssue,
  updateIssue,
  getTransactionByUuid,
  setReturnAddress,
  shipTransaction,
  startArbitration,
  closeIssue,
} from 'logic/actions/transactionsActions';
import { loadSingleMedia } from 'logic/actions/mediaActions';
import {
  INITIATOR,
  BUYER,
  SELLER,
  STATUS_ARBITRATION,
  STATUS_CLOSED,
  BASE_NS,
  RESPONSE_ACCEPT_REGEXP,
  STATUS_RETURN,
  STATUS_RETURN_DELIVERED,
  SOLUTION_FULL_REFUND,
  PENDING_STATUSES,
  ENDED_STATUSES,
  STATUS_MEDIATION,
} from 'helpers/constants';
import ellipsis from 'assets/images/ellipsis.gif';
import ArrowLeftIconWithLine from 'assets/svg/ArrowLeftIconWithLine';
import CloseIcon from 'assets/svg/CloseIcon';
import buyerRequestQuestions from 'assets/buyerRequest.yaml';
import sellerResponseQuestions from 'assets/sellerResponse.yaml';
import buyerResponseQuestions from 'assets/buyerResponse.yaml';
import { ReactComponent as StatusPending } from 'assets/images/statusPending.svg';
import { ReactComponent as StatusClosed } from 'assets/images/statusClosed.svg';

const DisputeResolution = ({
  me = {},
  transaction = {},
  getTransactionByUuid,
  openIssue,
  updateIssue,
  setReturnAddress,
  shipTransaction,
  startArbitration,
  closeIssue,
  loadSingleMedia,
  match: { params: { templateUuid } = {} } = {},
}) => {
  const setLoadingScreen = useLoadingScreen();
  const { t } = useTranslation('base');
  const intl = useIntl();

  const {
    userId,
    displayName,
  } = useMemo(() => (me), [me]);

  const {
    id,
    title,
    buyerUserId,
    sellerUserId,
    returnUrl,
    client,
    clientTheme: { primary = '#004bb4' } = {},
    status,
    messages = [],
    returnAddress = {},
    shippedAt,
    trackingNumber,
    trackingUrl,
    deliveredAt,
    arbitrationStartedAt,
    closedAt,
    cancelledAt,
  } = useMemo(() => (transaction), [transaction]);

  const lastMessage = useMemo(() => (
    messages?.length ? messages.slice(-1)[0] : null
  ), [messages]);

  useEffect(() => {
    setLoadingScreen(true);
    if (templateUuid) {
      getTransactionByUuid(templateUuid)
        .then(() => { setLoadingScreen(false); });
    }
  }, [templateUuid, getTransactionByUuid, setLoadingScreen]);

  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [isPending, setPending] = useState(false);
  const [showEvidenceForm, setShowEvidenceForm] = useState(false);
  const [showReturnAddressForm, setShowReturnAddressForm] = useState(false);
  const [showTrackingNumberForm, setShowTrackingNumberForm] = useState(false);
  const [showRecap, setShowRecap] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [role, setRole] = useState();
  const [turn, setTurn] = useState();
  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [showConfirmDeleteEvidenceModal, setShowConfirmDeleteEvidenceModal] = useState(false);

  const openConfirmDeleteEvidenceModal = useCallback(() => { setShowConfirmDeleteEvidenceModal(true); }, [setShowConfirmDeleteEvidenceModal]);

  const openEvidenceForm = useCallback(() => { setShowEvidenceForm(true); }, [setShowEvidenceForm]);
  const hideEvidenceForm = useCallback(() => { setShowEvidenceForm(false); }, [setShowEvidenceForm]);

  const openReturnAddressForm = useCallback(() => { setShowReturnAddressForm(true); }, [setShowReturnAddressForm]);
  const hideReturnAddressForm = useCallback(() => { setShowReturnAddressForm(false); }, [setShowReturnAddressForm]);

  const openTrackingNumberForm = useCallback(() => { setShowTrackingNumberForm(true); }, [setShowTrackingNumberForm]);
  const hideTrackingNumberForm = useCallback(() => { setShowTrackingNumberForm(false); }, [setShowTrackingNumberForm]);

  const openRecap = useCallback(() => { setShowRecap(true); }, [setShowRecap]);
  const hideRecap = useCallback(() => { setShowRecap(false); }, [setShowRecap]);

  const hasStartedAnswering = useMemo(() => (
    steps.filter(({ response }) => (!!response)).length !== 0
  ), [steps]);

  useEffect(() => {
    // Set role
    if (id && userId) { // If transaction and me have loaded
      if (lastMessage) {
        if (userId && buyerUserId === userId) {
          setRole(BUYER);
        }
        if (userId && sellerUserId === userId) {
          setRole(SELLER);
        }
      } else {
        setRole(INITIATOR);
      }

      // Set turn
      if (status === STATUS_ARBITRATION) {
        setTurn(null);
      } else if (lastMessage) {
        if (lastMessage.authorUserId === buyerUserId) {
          setTurn(SELLER);
        }
        if (lastMessage.authorUserId === sellerUserId) {
          setTurn(BUYER);
        }
      } else {
        setTurn(INITIATOR);
      }
    }
  }, [
    id,
    buyerUserId,
    sellerUserId,
    userId,
    setRole,
    setTurn,
    lastMessage,
    status,
  ]);

  useEffect(() => {
    if (role && turn) {
      if (!status || status === STATUS_MEDIATION) {
        if (!steps.length) {
          if (turn === role) {
            switch (turn) {
              case INITIATOR:
                setSteps([buyerRequestQuestions]);
                break;

              case BUYER:
                setSteps([buyerResponseQuestions]);
                break;

              case SELLER:
                setSteps([sellerResponseQuestions]);
                break;

              default:
            }
          }
        }
      }
    }
  }, [
    role,
    steps,
    messages,
    setSteps,
    turn,
    status,
  ]);

  const timeout = useRef();
  const bottomRef = useRef();

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToBottom = useCallback(() => {
    if (hasStartedAnswering && bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [hasStartedAnswering, bottomRef]);

  const onDocumentPreviewLoaded = useCallback(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  useEffect(() => {
    if (isPending) {
      if (!timeout.current) {
        timeout.current = setTimeout(() => {
          setPending(false);
          scrollToBottom();
        }, 500 + Math.random() * 1000);

        return () => {
          clearTimeout(timeout.current);
          timeout.current = null;
        };
      }
    } else if (timeout.current) {
      clearTimeout(timeout.current);
    }

    return () => {};
  }, [isPending, setPending, scrollToBottom]);

  useEffect(() => {
    if (showEvidenceForm || showRecap || showReturnAddressForm) {
      setPending(false);
      scrollToTop();
    } else {
      scrollToBottom();
    }
  }, [
    setPending,
    showEvidenceForm,
    showReturnAddressForm,
    showRecap,
    scrollToBottom,
    scrollToTop,
    steps,
  ]);

  const handleSubmit = useCallback(({
    issueType,
    proposedSolution,
    response,
    proposedRefund,
    data: rawData,
    medias: rawMedias,
    returnAddress,
  }) => {
    const mediaPromises = [];
    const medias = [];
    const data = [];

    setSubmitting(true);

    rawMedias.forEach(({ media, comment } = {}) => {
      mediaPromises.push(
        loadSingleMedia(media, comment).then(
          ({ payload: { data: { id } = [] } = {} }) => {
            medias.push(id);
          },
        ),
      );
    });

    rawData.forEach(({
      slug,
      response,
      ns,
      parentNs,
      type,
    }) => {
      data.push({
        slug,
        response,
        ns: ns || parentNs || BASE_NS,
        type,
      });
    });

    const operation = lastMessage ? updateIssue : openIssue;

    Promise.all(mediaPromises)
      .then(() => (
        returnAddress ? setReturnAddress(id, returnAddress) : Promise.resolve()
      ))
      .then(() => (
        operation(id, {
          issueType,
          proposedSolution,
          response,
          proposedRefund,
          data,
          medias,
        })
      ))
      .then(() => {
        setShowSuccessScreen(true);
      })
      .catch(() => {
        toast.error(t('sending_error'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [
    id,
    loadSingleMedia,
    openIssue,
    updateIssue,
    setReturnAddress,
    lastMessage,
    t,
  ]);

  const setStepResponse = useCallback((index, response) => {
    setSteps((prev) => {
      prev.splice(index, 1, {
        ...prev[index],
        response,
      });

      return prev;
    });
  }, [setSteps]);

  const setStepEvidence = useCallback((index, document, comment) => {
    setSteps((prev) => {
      prev.splice(index, 1, {
        ...prev[index],
        evidence: {
          document,
          comment,
        },
      });

      return prev;
    });
  }, [setSteps]);

  const setStepReturnAddress = useCallback((index, returnAddress) => {
    setSteps((prev) => {
      prev.splice(index, 1, {
        ...prev[index],
        returnAddress,
      });

      return prev;
    });
  }, [setSteps]);

  // Clear currentStep response and/or evidence, unless we're editing the current evidence
  useEffect(() => {
    if (steps?.length && !showEvidenceForm) {
      setSteps((prev) => {
        const lastIndex = prev.length - 1;
        prev.splice(lastIndex, 1, {
          ...prev[lastIndex],
          response: null,
          evidence: null,
        });

        return prev;
      });
    }
  }, [currentStep, setSteps, steps, showEvidenceForm]);

  const goForward = useCallback((newStep) => {
    setPending(true);
    setSteps((prev) => ([...prev, newStep]));
    setCurrentStep((prev) => (prev + 1));
    scrollToBottom();
  }, [
    setPending,
    setSteps,
    setCurrentStep,
    scrollToBottom,
  ]);

  const goBack = useCallback(() => {
    setSteps((prev) => (prev.slice(0, -1)));
    setCurrentStep((prev) => (prev - 1));
  }, [
    setSteps,
    setCurrentStep,
  ]);

  const {
    document: currentStepDocument = null,
    comment: currentStepComment = '',
  } = useMemo(() => (steps[currentStep]?.evidence || {}), [steps, currentStep]);

  const showBackButton = showReturnAddressForm || showTrackingNumberForm || (showEvidenceForm && !currentStepDocument);
  const showSteps = !showBackButton && !showRecap;

  const renderMessage = useCallback((message, index) => {
    if (
      message.response
      && message.proposedSolution
      && message.response.match(RESPONSE_ACCEPT_REGEXP)
      && message.proposedSolution.includes(SOLUTION_FULL_REFUND)
    ) {
      const deadline = moment(lastMessage?.createdAt)
        .add(3, 'days')
        .toDate();

      const tData = {
        deadlineDate: intl.formatDate(deadline),
        deadlineTime: intl.formatTime(deadline),
        returnAddress: returnAddress ? `${returnAddress.recipientName}, ${returnAddress.formattedAddress}` : null,
      };

      return (
        <p className={classes.textMessage}>
          <Trans
            t={t}
            i18nKey={`messages.${message.response}.${role}`}
            values={tData}
          />
        </p>
      );
    }

    return (
      <DisputeMessage
        transaction={transaction}
        message={message}
        role={role}
        index={index}
      />
    );
  }, [role, t, transaction, returnAddress, intl, lastMessage?.createdAt]);

  const renderEvents = useCallback(() => {
    if (!lastMessage) {
      return (<></>);
    }

    const deadline = moment(lastMessage?.createdAt)
      .add(3, 'days')
      .toDate();

    const tData = {
      client,
      displayName,
      deadlineDate: intl.formatDate(deadline),
      deadlineTime: intl.formatTime(deadline),
    };

    const validationDeadline = moment(deliveredAt)
      .add(3, 'days')
      .toDate();

    const isReturnCancel = lastMessage.response
      && lastMessage.proposedSolution === SOLUTION_FULL_REFUND
      && lastMessage.response.match(RESPONSE_ACCEPT_REGEXP)
      && cancelledAt;

    return (
      <div className={classes.messagesContainer}>
        {turn !== role || messages.length > 1 ? (
          <p className={classes.textMessage}>{t('messages.intro.pending.text', tData)}</p>
        ) : (
          <>
            <p className={classes.titleStart}>{t(`messages.intro.${role}.title`, tData)}</p>
            <p className={classes.textStart}>{t(`messages.intro.${role}.text`, tData)}</p>
          </>
        )}
        {messages.map((message, index) => (
          <>
            <p className={classes.textDate}>
              <FormattedDate value={message.createdAt} />
              <span className="mr-1" />
              <FormattedTime value={message.createdAt} />
            </p>
            {renderMessage(message, index, tData)}
          </>
        ))}
        {isReturnCancel && (
          <>
            <p className={classes.textDate}>
              <FormattedDate value={cancelledAt} />
              <span className="mr-1" />
              <FormattedTime value={cancelledAt} />
            </p>
            <p className={classes.textMessage}>{t(`messages.return_cancel.${role}`, { transactionTitle: title })}</p>
          </>
        )}
        {shippedAt && (
          <>
            <p className={classes.textDate}>
              <FormattedDate value={shippedAt} />
              <span className="mr-1" />
              <FormattedTime value={shippedAt} />
            </p>
            <p className={classes.textMessage}>
              <Trans
                t={t}
                i18nKey="messages.package_shipped"
                values={{ trackingNumber }}
                components={{ a: <a href={trackingUrl} target="_blank" rel="noopener noreferrer">ABC123</a> }}
              />
            </p>
          </>
        )}
        {deliveredAt && (
          <>
            <p className={classes.textDate}>
              <FormattedDate value={deliveredAt} />
              <span className="mr-1" />
              <FormattedTime value={deliveredAt} />
            </p>
            <p className={classes.textMessage}>
              <Trans
                t={t}
                i18nKey={`messages.package_delivered.${role}`}
                values={{
                  deadlineDate: intl.formatDate(validationDeadline),
                  deadlineTime: intl.formatTime(validationDeadline),
                }}
              />
            </p>
          </>
        )}
        {deliveredAt && status === STATUS_ARBITRATION && (
          <>
            <p className={classes.textDate}>
              <FormattedDate value={arbitrationStartedAt} />
              <span className="mr-1" />
              <FormattedTime value={arbitrationStartedAt} />
            </p>
            <p className={classes.textMessage}>{t(`messages.return_arbitration.${role}`)}</p>
          </>
        )}
        {status === STATUS_CLOSED && (
          <>
            <p className={classes.textDate}>
              <FormattedDate value={closedAt} />
              <span className="mr-1" />
              <FormattedTime value={closedAt} />
            </p>
            <p className={classes.textMessage}>{t(`messages.closed.${role}`)}</p>
          </>
        )}
        {!deliveredAt && status === STATUS_ARBITRATION && (
          <p className={classes.textMessage}>{t('messages.outro.arbitration.text', tData)}</p>
        )}
        {status !== STATUS_ARBITRATION && messages.length === 1 && role === BUYER && (
          <p className={classes.textMessage}>{t('messages.outro.pending_seller_response.text', tData)}</p>
        )}
      </div>
    );
  }, [
    client,
    displayName,
    intl,
    lastMessage,
    messages,
    role,
    status,
    t,
    turn,
    renderMessage,
    trackingNumber,
    trackingUrl,
    deliveredAt,
    shippedAt,
    arbitrationStartedAt,
    closedAt,
    title,
    cancelledAt,
  ]);

  const renderContent = useCallback(() => {
    if (showSuccessScreen) {
      return <SuccessScreen role={role} returnUrl={returnUrl} />;
    }

    if (showRecap) {
      return (
        <DisputeRecap
          transaction={transaction}
          role={role}
          steps={steps}
          submitting={submitting}
          hideRecap={hideRecap}
          submit={handleSubmit}
        />
      );
    }

    if (showEvidenceForm) {
      return (
        <AddEvidenceForm
          addEvidence={(document, comment) => {
            setStepEvidence(currentStep - 1, document, comment);
            hideEvidenceForm();
          }}
          document={currentStepDocument}
          comment={currentStepComment}
        />
      );
    }

    if (showReturnAddressForm) {
      return (
        <ReturnAddressForm
          returnDeliveryAddress={returnAddress}
          setReturnAddress={(returnAddress) => {
            setStepReturnAddress(currentStep - 1, returnAddress);
            hideReturnAddressForm();
          }}
        />
      );
    }

    if (showTrackingNumberForm) {
      return (
        <TrackingNumberForm
          startTracking={(data) => (
            shipTransaction(id, data)
              .then(() => {
                hideTrackingNumberForm();
              })
              .catch(() => {
                toast.error(t('shipping_error'));
              })
          )}
        />
      );
    }

    if (showSteps) {
      return (
        <>
          {renderEvents()}
          <div className={classes.stepsContainer}>
            {steps
              .filter((elt, index) => (
                isPending
                  ? index <= currentStep - 1
                  : index <= currentStep
              ))
              .map((node, index) => (
                <DisputeStep
                  node={node}
                  me={me}
                  transaction={transaction}
                  role={role}
                  index={index}
                  currentStep={currentStep}
                  key={index} // eslint-disable-line react/no-array-index-key
                  goBack={goBack}
                  goForward={goForward}
                  openEvidenceForm={openEvidenceForm}
                  openReturnAddressForm={openReturnAddressForm}
                  openConfirmDeleteEvidenceModal={openConfirmDeleteEvidenceModal}
                  setResponse={(response) => {
                    setStepResponse(index, response);
                  }}
                  onDocumentPreviewLoaded={onDocumentPreviewLoaded}
                  openRecap={openRecap}
                  exit={() => {
                    setPending(false);
                    window.location.href = returnUrl;
                  }}
                />
              ))}
            {isPending && (
              <img className={classes.ellipsis} src={ellipsis} alt="pending..." />
            )}
            {status === STATUS_RETURN && role === BUYER && (
              <StickyButtons className={classes.buttons}>
                <Button
                  className={classes.trackingNumberButton}
                  variant="light"
                  onClick={openTrackingNumberForm}
                >
                  {t('buttons.enter_tracking_number')}
                </Button>
              </StickyButtons>
            )}
            {status === STATUS_RETURN_DELIVERED && role === SELLER && (
              <StickyButtons className={classes.buttons}>
                <ButtonWithConfirmationModal
                  className={classes.trackingNumberButton}
                  variant="light"
                  modalTitle={t('confirm_return_modal.title')}
                  modalMessage={t('confirm_return_modal.text')}
                  confirmButtonLabel={t('confirm_return_modal.confirm')}
                  cancelButtonLabel={t('confirm_return_modal.cancel')}
                  onConfirm={() => (closeIssue(id))}
                >
                  {t('buttons.confirm_return')}
                </ButtonWithConfirmationModal>
                <ButtonWithConfirmationModal
                  className={classes.trackingNumberButton}
                  variant="light"
                  modalTitle={t('open_issue_return_modal.title')}
                  modalMessage={t('open_issue_return_modal.text')}
                  confirmButtonLabel={t('open_issue_return_modal.confirm')}
                  cancelButtonLabel={t('open_issue_return_modal.cancel')}
                  onConfirm={() => (startArbitration(id))}
                >
                  {t('buttons.report_issue')}
                </ButtonWithConfirmationModal>
              </StickyButtons>
            )}
          </div>
        </>
      );
    }

    return <div />;
  }, [
    currentStep,
    currentStepComment,
    currentStepDocument,
    goBack,
    goForward,
    handleSubmit,
    hideEvidenceForm,
    hideRecap,
    isPending,
    me,
    onDocumentPreviewLoaded,
    openConfirmDeleteEvidenceModal,
    openEvidenceForm,
    openReturnAddressForm,
    openRecap,
    returnUrl,
    role,
    setStepEvidence,
    setStepResponse,
    showEvidenceForm,
    showRecap,
    showSteps,
    showSuccessScreen,
    steps,
    submitting,
    transaction,
    hideReturnAddressForm,
    renderEvents,
    returnAddress,
    setStepReturnAddress,
    showReturnAddressForm,
    status,
    t,
    hideTrackingNumberForm,
    openTrackingNumberForm,
    showTrackingNumberForm,
    id,
    shipTransaction,
    startArbitration,
    closeIssue,
  ]);

  return (
    <>
      <Helmet>
        <style>{clientCssGenerator(primary)}</style>
      </Helmet>
      <SimpleConfirmModal
        show={showConfirmExitModal}
        onConfirm={() => {
          setShowConfirmExitModal(false);
          window.location.href = returnUrl;
        }}
        onHide={() => {
          setShowConfirmExitModal(false);
        }}
        onCancel={() => {
          setShowConfirmExitModal(false);
        }}
        title={t('confirmExitModal.title')}
        text={t('confirmExitModal.text')}
        confirmButtonLabel={t('confirmExitModal.confirm')}
        cancelButtonLabel={t('confirmExitModal.cancel')}
      />
      <SimpleConfirmModal
        show={showConfirmDeleteEvidenceModal}
        onConfirm={() => {
          setShowConfirmDeleteEvidenceModal(false);
          goBack();
        }}
        onHide={() => {
          setShowConfirmDeleteEvidenceModal(false);
        }}
        onCancel={() => {
          setShowConfirmDeleteEvidenceModal(false);
        }}
        title={t('deleteEvidenceModal.title')}
        confirmButtonLabel={t('deleteEvidenceModal.confirm')}
        cancelButtonLabel={t('deleteEvidenceModal.cancel')}
      />
      <div className={classes.container}>
        <div className={classes.header}>
          {showBackButton && (
            <Button
              variant="light"
              className={classes.goBack}
              onClick={() => {
                goBack();
                hideEvidenceForm();
                hideReturnAddressForm();
                hideTrackingNumberForm();
              }}
            >
              <ArrowLeftIconWithLine />
            </Button>
          )}

          <p className={classes.title}>{t('header')}</p>

          {returnUrl && (
            <Button
              variant="light"
              className={classes.exit}
              onClick={() => {
                setShowConfirmExitModal(true);
              }}
            >
              <CloseIcon />
            </Button>
          )}
        </div>

        {status && turn !== INITIATOR && !showBackButton && (
          <div className={classes.statusBar}>
            {PENDING_STATUSES.includes(status) && <StatusPending />}
            {ENDED_STATUSES.includes(status) && <StatusClosed />}
            <p>{t(`status.${status}`)}</p>
          </div>
        )}

        <div className={classes.content}>
          {renderContent()}
        </div>
        <div ref={bottomRef} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  transaction: state.persistent.transactionsReducer.currentTransaction,
});

const mapDispatchToProps = (dispatch) => ({
  getTransactionByUuid: bindActionCreators(getTransactionByUuid, dispatch),
  openIssue: bindActionCreators(openIssue, dispatch),
  updateIssue: bindActionCreators(updateIssue, dispatch),
  setReturnAddress: bindActionCreators(setReturnAddress, dispatch),
  shipTransaction: bindActionCreators(shipTransaction, dispatch),
  startArbitration: bindActionCreators(startArbitration, dispatch),
  closeIssue: bindActionCreators(closeIssue, dispatch),
  loadSingleMedia: bindActionCreators(loadSingleMedia, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisputeResolution);
