/* eslint-disable import/no-unused-modules */
export const FETCH_CLIENT_SQUARE_LOGO = 'app/clients/FETCH_CLIENT_SQUARE_LOGO';
export const FETCH_CLIENT_LOGO = 'app/clients/FETCH_CLIENT_LOGO';
export const SET_CLIENT_PERMALINK = 'app/clients/SET_CLIENT_PERMALINK';
/* eslint-enable import/no-unused-modules */

export const fetchClientSquareLogo = (clientId) => ({
  type: FETCH_CLIENT_SQUARE_LOGO,
  clientId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dispute/clients/${clientId}/square-logo/`,
    },
  },
});

export const setClientPermalink = ({ permalink, clientId }) => ({
  type: SET_CLIENT_PERMALINK,
  permalink,
  clientId,
});

export const fetchClientLogo = (clientId) => ({
  type: FETCH_CLIENT_LOGO,
  clientId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dispute/clients/${clientId}/platform-logo/`,
    },
  },
});

export const selectLogo = (clientId) => (state) => state.persistent.clientsReducer[clientId]?.logo;
export const selectSquareLogo = (clientId) => (state) => state.persistent.clientsReducer[clientId]?.squareLogo;
export const selectPermalink = (clientId) => (state) => state.persistent.clientsReducer[clientId]?.permalink;
