import React, {
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import classes from 'assets/style/textAreaAnswer.module.scss';

const TextAreaAnswer = ({
  placeholder,
  onSubmit = () => {},
}) => {
  const { t } = useTranslation('textarea_answer');

  const [value, setValue] = useState();

  const submit = useCallback(() => {
    onSubmit(value);
  }, [value, onSubmit]);

  return (
    <div className={classes.container}>
      <FormControl
        as="textarea"
        placeholder={placeholder}
        className={classes.input}
        value={value}
        onChange={({ target: { value } = {} } = {}) => {
          setValue(value);
        }}
      />
      <Button
        variant="dark"
        disabled={!value}
        className={classes.submit}
        onClick={submit}
      >
        {t('submit')}
      </Button>
    </div>
  );
};

export default TextAreaAnswer;
