import { clientId, clientSecret } from 'helpers/env';
import {
  syncLoggedIn,
  syncRefreshToken,
} from 'logic/actions/syncActions';
import parseUserDevice from 'helpers/userDevice';

/* eslint-disable import/no-unused-modules */
export const GET_TOKEN = 'auth/GET_TOKEN';
export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
/* eslint-enable import/no-unused-modules */

const browserInfo = parseUserDevice();

const GRANT_TYPE_DISPUTE = 'https://docs.tripartie.com/#section/Authentication/LoginLink';

export const doGetToken = ({
  email,
  password,
  token,
  ...restParams
}) => ({
  type: GET_TOKEN,
  noAuth: true,
  payload: {
    request: {
      withCredentials: true,
      method: 'POST',
      url: '/oauth/v2/token/',
      data: {
        username: email,
        password,
        token,
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: GRANT_TYPE_DISPUTE,
        ...restParams,
        browserInfo,
      },
    },
  },
});

export const getToken = ({
  email,
  password,
  disputeToken,
  ...restParams
}) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(
    doGetToken({
      email,
      password,
      disputeToken,
      ...restParams,
    }),
  )
    .then(({ payload: { data: { accessToken, expiresAt, scope } = {} } = {} }) => {
      dispatch(syncLoggedIn(accessToken, expiresAt, scope));
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
});

export const doRefreshToken = () => ({
  type: REFRESH_TOKEN,
  noAuth: true,
  payload: {
    request: {
      withCredentials: true,
      method: 'POST',
      url: '/oauth/v2/token/',
      data: {
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: 'refresh_token',
        browserInfo,
      },
    },
  },
});

export const refreshToken = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(doRefreshToken())
    .then(({ payload: { data: { accessToken, expiresAt, scope } = {} } = {} }) => {
      dispatch(syncRefreshToken(accessToken, expiresAt, scope));
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
});

export const selectAccessToken = (state) => state.volatile.authReducer.accessToken;
