import { createReducer } from '@reduxjs/toolkit';
import { success, shouldResetState } from 'logic/reducers/helpers';
import {
  GET_TRANSACTION_BY_UUID,
  OPEN_ISSUE,
  UPDATE_ISSUE,
  SET_RETURN_ADDRESS,
  SHIP_TRANSACTION,
  START_ARBITRATION,
  CLOSE_ISSUE,
} from 'logic/actions/transactionsActions';

const initialState = {
  currentTransaction: [],
};

const handleGetTransaction = (
  state,
  { payload: { data = {} } = {} },
) => ({
  currentTransaction: data,
});

export default createReducer(
  initialState,
  {
    [success(GET_TRANSACTION_BY_UUID)]: handleGetTransaction,
    [success(OPEN_ISSUE)]: handleGetTransaction,
    [success(UPDATE_ISSUE)]: handleGetTransaction,
    [success(SET_RETURN_ADDRESS)]: handleGetTransaction,
    [success(SHIP_TRANSACTION)]: handleGetTransaction,
    [success(START_ARBITRATION)]: handleGetTransaction,
    [success(CLOSE_ISSUE)]: handleGetTransaction,
  },
  [
    {
      matcher: ({ type }) => shouldResetState({ type }),
      reducer: () => initialState,
    },
  ],
);
