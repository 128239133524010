import React, {
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import classes from 'assets/style/addEvidenceForm.module.scss';
import Button from 'components/Button';
import DocumentPicker from 'components/DocumentPicker';
import { ISSUE_PROOFS_FILE_FORMATS } from 'helpers/constants';

const AddEvidenceForm = ({
  addEvidence,
  document: initialDocument = null,
  comment: initialComment = '',
}) => {
  const { t, i18n } = useTranslation('add_evidence', { keyPrefix: 'form' });

  const [comment, setComment] = useState(initialComment);
  const [document, setDocument] = useState(initialDocument);

  const showDescription = useMemo(() => (
    i18n.exists('add_evidence:form.description')
  ), [i18n]);

  return (
    <div className={classes.content}>
      <p className={classes.title}>{t('title')}</p>
      {showDescription && (
        <p className={classes.description}>{t('description')}</p>
      )}
      <DocumentPicker
        acceptFileTypes={ISSUE_PROOFS_FILE_FORMATS}
        setDocument={setDocument}
        document={document}
        addButtonText={t('addMedia')}
      />
      <div className={classes.comment}>
        <p className={classes.title}>{t('comment.title')}</p>
        <div className={classes.textareaContainer}>
          <Form.Control
            as="textarea"
            className={classes.textarea}
            value={comment}
            placeholder={t('comment.placeholder')}
            onChange={({ target: { value } = {} } = {}) => {
              setComment(value);
            }}
          />
          <p className={classes.counter}>{comment.length}</p>
        </div>
      </div>
      <Button
        className={classes.submit}
        disabled={!comment || !document}
        onClick={() => {
          addEvidence(document, comment);
        }}
      >
        {t('submit')}
      </Button>
    </div>
  );
};

export default AddEvidenceForm;
