import React, { useEffect, useState } from 'react';
import { getBase64FromBlob, getBase64FromPdfFile } from 'helpers/decode';
import { Spinner } from 'react-bootstrap';
import classes from 'assets/style/documentPreview.module.scss';
import { ReactComponent as DownloadIcon } from 'assets/images/downloadIcon.svg';
import { ReactComponent as EditEvidenceIcon } from 'assets/images/editEvidenceIcon.svg';
import { ReactComponent as RemoveEvidenceIcon } from 'assets/images/removeEvidenceIcon.svg';

const DocumentPreview = ({
  file = {},
  onEdit,
  onDelete,
  onDownload,
  onLoaded = () => {},
}) => {
  const [localFile, setLocalFile] = useState();
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (file?.size && file?.size !== localFile?.size) {
      setLocalFile(file);
      setLoading(true);

      if (file.type === 'application/pdf') {
        getBase64FromPdfFile(file)
          .then((url) => {
            setUrl(url);
          })
          .finally(() => {
            setLoading(false);
            onLoaded();
          });
      } else {
        getBase64FromBlob(file)
          .then((url) => {
            setUrl(url);
          })
          .finally(() => {
            setLoading(false);
            onLoaded();
          });
      }
    }
  }, [file, localFile, setLocalFile, setLoading, setUrl, onLoaded]);

  return (
    <div className={classes.container}>
      {loading ? (
        <Spinner variant="secondary" animation="grow" />
      ) : (
        <>
          <div className={classes.editDeleteBtnContainer}>
            {onEdit && (
              <button
                className={classes.editBtn}
                type="button"
                onClick={() => {
                  onEdit(file);
                }}
              >
                <EditEvidenceIcon />
              </button>
            )}
            {onDelete && (
              <button
                className={classes.deleteBtn}
                type="button"
                onClick={() => {
                  onDelete(file);
                }}
              >
                <RemoveEvidenceIcon />
              </button>
            )}
          </div>
          {onDownload && (
            <button
              className={classes.downloadBtn}
              type="button"
              onClick={() => {
                onDownload(file);
              }}
            >
              <div>
                <DownloadIcon />
              </div>
            </button>
          )}
          <img src={url} alt="Evidence" />
        </>
      )}
    </div>
  );
};

export default DocumentPreview;
