import authReducer from 'logic/reducers/authReducer';
import meReducer from 'logic/reducers/meReducer';
import transactionsReducer from 'logic/reducers/transactionsReducer';
import clientsReducer from 'logic/reducers/clientsReducer';
import countriesReducer from 'logic/reducers/countriesReducer';

export const persistentReducers = {
  meReducer,
  transactionsReducer,
  clientsReducer,
  countriesReducer,
};

export const volatileReducers = {
  authReducer,
};
